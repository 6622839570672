import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Grid, MenuItem, TextField, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckedIcon from '../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../assets/images/icons/UncheckedIcon.png';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import classes from "../../../assets/styles/Task.module.css";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import Message from '../../../sharedComponents/ui/Message';
import moment from "moment";
import 'moment/locale/de';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAppSelector } from '../../../app/hooks';
import { selectUserType } from '../../../app/slices/userDataSlice';
import InputAdornment from '@mui/material/InputAdornment';
import { useIntl } from 'react-intl';
import SearchIcon from '@mui/icons-material/Search';
import 'moment/locale/en-gb';
import * as taskConsts from "../../../task-consts";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export interface DialogProps {
  open: boolean;
  setOpen: CallableFunction;
  loading: any,
  callStatus: any,
  errorMsg: any,
  confirmAction: any,
  assigneesList: any;
  clientsList: any;
  statusesList: any;
  typesList: any;
  moduleList: any;
  recurringPeriodsList: any;
  internalAssigneesList: any;
  externalAssigneesList: any;
  setExternalAssigneesList: any;
  taskObject: any;
  setTaskObject: any;
  inputDataLoading: any;
}

const CreateTask = (props: DialogProps) => {

  const { open, setOpen, loading, callStatus, errorMsg, confirmAction, assigneesList, clientsList, statusesList, typesList, moduleList, recurringPeriodsList, internalAssigneesList, externalAssigneesList, setExternalAssigneesList, taskObject, setTaskObject,
    inputDataLoading
  } = props;

  const intl = useIntl();

  const [iseRecuringTask, setIsRecuringTask] = useState(false);
  const [selectedButton, setSelectedButton] = useState(0);

  const [openAssigneesDropdown, setOpenAssigneesDropdown] = useState(false);
  const [assigneesType, setAssigneesType] = useState('External');
  const userType = useAppSelector(selectUserType);

  // const [search, setSearch] = useState<string>();
  // const [filteredInternalAssignees, setFilteredInternalAssignees] = useState(internalAssigneesList);
  // const [filteredExternalAssignees, setFilteredExternalAssignees] = useState(externalAssigneesList);
  const [internalAssigneeSearch, setInternalAssigneeSearch] = useState('');
  const [externalAssigneeSearch, setExternalAssigneeSearch] = useState('');
  const [filteredExternalAssigneesList, setFilteredExternalAssigneesList] = useState(externalAssigneesList);
  const [filteredInternalAssigneesList, setFilteredInternalAssigneesList] = useState(internalAssigneesList);
  const prioritiesList = [1, 2, 3, 4, 5];


  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: '0px 2px 4px 0px #0000001F',
      backgroundColor: '#FEFEFF',
      borderRadius: '10px',
      padding: 0,
      color: 'black',
    },
  }));


  const handleOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAssigneesDropdown(false);
  };

  useEffect(() => {
    if (open) {
      handleOpen();
    }
    else {
      setOpen(false);
      setTaskObject({
        title: "",
        dueDate: "",
        description: "",
        status: 1,
        type: 1,
        module: 0,
        clientId: localStorage.getItem('userType') === '1' ? "" : null,
        assigneeId: "",
        priority: 3,
        recurringPeriod: 0,
        note: ""
      })
    }
  }, [open]);

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => {
    // Updating state with an empty object to trigger a re-render
    updateState({});
  }, []);
const [dueError, setDueError]= useState(false);
const [startError, setStartError]= useState(false);
  const handleDateChange = (newValue: any) => {
      if(newValue && moment(newValue).isValid() && moment(newValue).isSameOrAfter(moment(), 'day') && (!taskObject.startDate || moment(newValue).isSameOrAfter(moment(taskObject.startDate, 'YYYY-MM-DD'), 'day'))){
    setTaskObject((prevState: any) => ({
      ...prevState,
      dueDate: newValue ? newValue.format('YYYY-MM-DD') : null,
    }));
    setDueError(false);
  }
  else{
    setDueError(true);
  }
  };

  const handleStartDateChange = (newValue: any) => {
     if(newValue && moment(newValue).isValid() && moment(newValue).isSameOrAfter(moment(), 'day') && (!taskObject.dueDate || moment(newValue).isSameOrBefore(moment(taskObject.dueDate, 'YYYY-MM-DD'), 'day'))){
    setTaskObject((prevState: any) => ({
      ...prevState,
      startDate: newValue ? newValue.format('YYYY-MM-DD') : null,
    }));
    setStartError(false);
  }
  else{
    setStartError(true);
  }
  };

  const handleRecuringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRecuringTask(event.target.checked);
  };

  useEffect(() => {
    if (openAssigneesDropdown) {
      if (assigneesType === 'Internal') {
        setFilteredInternalAssigneesList(internalAssigneesList);
      } else {
        setFilteredExternalAssigneesList(externalAssigneesList);
      }
    }
  }, [openAssigneesDropdown, assigneesType, internalAssigneesList, externalAssigneesList]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ sx: { borderRadius: "8px", width: "865px", maxWidth: "none", } }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={inputDataLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid item container padding={2.5} direction='column' rowGap={2} >
        <Grid item container className='neutral-4' fontWeight={400} alignItems='center' justifyContent='space-between'>
          <Message id="task.create-new-task" className='Field-input font-weight-400 font-14' />
          <span style={{ cursor: 'pointer' }} onClick={() => handleClose()}><CloseIcon /></span>
          <Grid item className='greyLine' width='100%' xs={12}></Grid>

        </Grid>
        <Grid item container direction='column'>
          <Grid item className='neutral-9 font-weight-400'><Message id="task.name" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
          <Grid item>
            <TextField
              fullWidth
              label={taskObject.title ? '' : <div style={{ display: 'inline-flex' }}><Message id="task.name" className='Field-input font-weight-400 mobile-paragraph' /> <span style={{ color: 'red', marginLeft: '5px' }}>*</span></div>}
              value={taskObject.title}
              onChange={(e) => {
                taskObject.title = e.target.value
                forceUpdate();
              }}
              InputLabelProps={{
                shrink: false,
              }}
              sx={{
                '& label.Mui-focused': {
                  color: '#C3C2C7',
                },
                '& .MuiInputLabel-root': {
                  color: '#C3C2C7',
                  marginTop: '-7px',
                  fontSize: '16px'
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#C3C2C7',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#C3C2C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6F7E8C',
                  },
                },
              }}
              InputProps={{ className: 'fieldDesign' }}
            />
          </Grid>
        </Grid>

        <Grid item container direction='column'>
          <Grid item className='neutral-9 font-weight-400'><Message id="task.description" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
          <Grid item>
            <TextField
              fullWidth
              multiline
              label={taskObject.description ? '' : <div style={{ display: 'inline-flex' }}><Message id="task.description" className='Field-input font-weight-400 mobile-paragraph' /></div>}
              minRows={3}
              value={taskObject.description}
              onChange={(e) => {
                taskObject.description = e.target.value
                forceUpdate();
              }}
              InputLabelProps={{
                shrink: false,
              }}
              sx={{
                '& label.Mui-focused': {
                  color: '#C3C2C7',
                },
                '& .MuiInputLabel-root': {
                  color: '#C3C2C7',
                  marginTop: '-7px',
                  fontSize: '16px'
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#C3C2C7',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#C3C2C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6F7E8C',
                  },
                },
              }}
              InputProps={{ className: 'descriptionDesign' }}
            // inputProps={{ maxLength: 50 }}
            />
          </Grid>
        </Grid>


        <Grid item container direction='row' columnSpacing={1.5} rowSpacing={1.8}>

          {/* {localStorage.getItem('userType') === '1' ? */}
          <Grid item container direction='column' xs={4}>
            <Grid item><Message id="task.client" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
            <Grid item width='100%'>
              <TextField
                disabled={userType === 2}
                fullWidth
                select
                label={taskObject.clientId ? '' : <div style={{ display: 'inline-flex' }}><Message id="task.client" className='Field-input font-weight-400 mobile-paragraph' /> <span style={{ color: 'red', marginLeft: '5px' }}>*</span></div>}
                value={taskObject.clientId}
                onChange={(e) => {
                  taskObject.clientId = e.target.value
                  if (userType === 1) {
                    const clientTMs = clientsList.find((i: any) => i.id === e.target.value)?.users
                    setExternalAssigneesList(clientTMs)
                    taskObject.assigneeId = ""

                  }
                  forceUpdate();
                }}
                InputLabelProps={{
                  shrink: false,
                }}
                sx={{
                  '& label.Mui-focused': {
                    color: '#C3C2C7',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#C3C2C7',
                    marginTop: '-7px',
                    fontSize: '16px'
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#C3C2C7',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#B2BAC2',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#C3C2C7',
                    },
                    '&:hover fieldset': {
                      borderColor: '#B2BAC2',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#6F7E8C',
                    },
                  },
                }}
                InputProps={{ className: 'fieldDesign' }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 150, // Set the max height of the dropdown
                        width: 'fit-content', // Adjust width if needed
                      },
                    },
                  },
                }}
              >
                {clientsList?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          {/* :
            <></>
          } */}

          <Grid item container direction='column' xs={4}>
            <Grid item><Message id="task.module" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
            <Grid item>
              <TextField
                fullWidth
                value={taskObject.module}
                label={taskObject.module ? '' : <div style={{ display: 'inline-flex' }}><Message id="task.module" className='Field-input font-weight-400 mobile-paragraph' /> <span style={{ color: 'red', marginLeft: '5px' }}>*</span></div>}
                select
                onChange={(e) => {
                  // taskObject.module = parseInt(e.target.value);
                  // forceUpdate();
                  setTaskObject({ ...taskObject, module: parseInt(e.target.value) })
                }}
                sx={{
                  '& label.Mui-focused': {
                    color: '#C3C2C7',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#C3C2C7',
                    marginTop: '-7px',
                    fontSize: '16px'
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#C3C2C7',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#B2BAC2',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#C3C2C7',
                    },
                    '&:hover fieldset': {
                      borderColor: '#B2BAC2',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#6F7E8C',
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{ className: 'fieldDesign' }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 150, // Set the max height of the dropdown
                        width: 'fit-content', // Adjust width if needed
                      },
                    },
                  },
                }}
              >
                {moduleList?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid item container direction='column' xs={4}>
            <Grid item><Message id="task.task-type" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
            <Grid item>
              <TextField
                fullWidth
                select
                label={taskObject.type ? '' : <div style={{ display: 'inline-flex' }}><Message id="task.type" className='Field-input font-weight-400 mobile-paragraph' /> <span style={{ color: 'red', marginLeft: '5px' }}>*</span></div>}
                value={taskObject.type}
                onChange={(e) => {
                  taskObject.type = parseInt(e.target.value);
                  forceUpdate();
                }}
                InputLabelProps={{
                  shrink: false,
                }}
                sx={{
                  '& label.Mui-focused': {
                    color: '#C3C2C7',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#C3C2C7',
                    marginTop: '-7px',
                    fontSize: '16px'
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#C3C2C7',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#B2BAC2',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#C3C2C7',
                    },
                    '&:hover fieldset': {
                      borderColor: '#B2BAC2',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#6F7E8C',
                    },
                  },
                }}
                InputProps={{ className: 'fieldDesign' }}
              >
                {typesList?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          {/* <Grid item container direction='column' xs={6}>
            <Grid item><Message id="user.status" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
            <Grid item>
              <TextField
                fullWidth
                value={taskObject.status}
                disabled
                select
                onChange={(e) => {
                  taskObject.status = parseInt(e.target.value);
                  forceUpdate();
                }}
                sx={{
                  '& label.Mui-focused': {
                    color: '#C3C2C7',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#B2BAC2',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#C3C2C7',
                    },
                    '&:hover fieldset': {
                      borderColor: '#B2BAC2',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#6F7E8C',
                    },
                  },
                }}
                InputProps={{ className: 'fieldDesign' }}
              >
                {statusesList?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid> */}
          <Grid item container direction='column' xs={6}>
            <Grid item>
              <Message id="task.start-date" className='Field-input font-weight-400 mobile-paragraph' />
            </Grid>
            <Grid item>
              {/* <TextField
                fullWidth
                value={taskObject.dueDate}
                onChange={(e) => {
                  taskObject.dueDate = e.target.value
                  forceUpdate();
                }}
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{ className: 'fieldDesign' }}
                size="small"
                label={""}
                type={"date"}
                variant="outlined"
              /> */}
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
                <Stack spacing={3}>
                  <DatePicker
                    label={(taskObject.startDate || startError ) ? '' : (
                      <div style={{ display: 'inline-flex' }}>
                        <span style={{ fontSize: '1rem' }}>DD.MM.YYYY</span>
                        <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                      </div>
                    )}
                    shouldDisableDate={(date) =>
                      date.isBefore(moment(), 'day')
                    }
                    value={taskObject.startDate ? moment(taskObject.startDate, 'YYYY-MM-DD') : null}
                    onChange={handleStartDateChange}
                    maxDate={taskObject.dueDate ? moment(taskObject.dueDate, 'YYYY-MM-DD') : undefined}
                    slotProps={{
                      textField: {
                        error: startError,
                        InputLabelProps: {
                          shrink: false,

                        },
                        sx: {
                          '& label.Mui-focused': {
                            color: '#C3C2C7',
                            visibility: 'hidden',
                            display: 'none'
                          },
                          '& .MuiInputLabel-root': {
                            color: '#C3C2C7',
                            marginTop: '-7px',
                            fontSize: '16px'
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#C3C2C7',
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#B2BAC2',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#C3C2C7',
                            },
                            '&:hover fieldset': {
                              borderColor: '#B2BAC2',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#6F7E8C',
                            },
                          },
                          '& .MuiInputBase-input::placeholder': {
                            color: 'transparent',
                          },
                        },
                        InputProps: {
                          className: "fieldDesign",
                        },
                      },
                    }}
                    // format='DD-MM-YYYY'
                    // format="DD/MM/YYYY"
                    format="DD.MM.YYYY"
                  />

                </Stack>
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid item container direction='column' xs={6}>
            <Grid item><Message id="task.due-date" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
            <Grid item>
              {/* <TextField
                fullWidth
                value={taskObject.dueDate}
                onChange={(e) => {
                  taskObject.dueDate = e.target.value
                  forceUpdate();
                }}
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{ className: 'fieldDesign' }}
                size="small"
                label={""}
                type={"date"}
                variant="outlined"
              /> */}
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
                <Stack spacing={3}>
                  <DatePicker
                    label={(taskObject.dueDate || dueError) ? '' : (
                      <div style={{ display: 'inline-flex' }}>
                        <span style={{ fontSize: '1rem' }}>DD.MM.YYYY</span>
                        <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                      </div>
                    )}
                    shouldDisableDate={(date) =>
                      date.isBefore(moment(), 'day')
                    }
                    value={taskObject.dueDate ? moment(taskObject.dueDate, 'YYYY-MM-DD') : null}
                    onChange={handleDateChange}
                    minDate={taskObject.startDate ? moment(taskObject.startDate, 'YYYY-MM-DD') : undefined}
                    slotProps={{
                      textField: {
                        error: dueError,
                        InputLabelProps: {
                          shrink: false,

                        },
                        sx: {
                          '& label.Mui-focused': {
                            color: '#C3C2C7',
                            visibility: 'hidden',
                            display: 'none'
                          },
                          '& .MuiInputLabel-root': {
                            color: '#C3C2C7',
                            marginTop: '-7px',
                            fontSize: '16px'
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#C3C2C7',
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#B2BAC2',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#C3C2C7',
                            },
                            '&:hover fieldset': {
                              borderColor: '#B2BAC2',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#6F7E8C',
                            },
                            '& .MuiInputBase-input::placeholder': {
                              color: 'transparent',
                            },
                          },
                        },
                        InputProps: {
                          className: "fieldDesign",
                        },
                      },
                    }}
                    // format='DD-MM-YYYY'
                    // format="DD/MM/YYYY"
                    format="DD.MM.YYYY"
                  />

                </Stack>
              </LocalizationProvider>
            </Grid>
          </Grid>

        </Grid>

        {/* <Grid item className='neutral-9 font-weight-400 '>
          {localStorage.getItem('userType') === '1' ?
            <Message id="task.client-assignee-info" className='Field-input font-weight-400 mobile-paragraph' />
            :
            "Assignee Info"
          }

        </Grid> */}

        {/* <Grid item container direction='row' columnSpacing={1.5}>
          {localStorage.getItem('userType') === '1' ?
            <Grid item container direction='column' xs={6}>
              <Grid item><Message id="task.client" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
              <Grid item width='100%'>
                <TextField
                  fullWidth
                  select
                  value={taskObject.clientId}
                  onChange={(e) => {
                    taskObject.clientId = e.target.value
                    if (userType === 1) {
                      const clientTMs = clientsList.find((i: any) => i.id === e.target.value)?.users
                      setExternalAssigneesList(clientTMs)
                      taskObject.assigneeId = ""

                    }
                    forceUpdate();
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: '#C3C2C7',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#B2BAC2',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#C3C2C7',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B2BAC2',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  InputProps={{ className: 'fieldDesign' }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 150, // Set the max height of the dropdown
                          width: 'fit-content', // Adjust width if needed
                        },
                      },
                    },
                  }}
                >
                  {clientsList?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            :
            <></>
          } */}
        <Grid item container direction='column' xs={12}>
          <Grid item><Message id="task.assignee" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
          <Tooltip
            slotProps={{
              tooltip: {
                sx: {
                  boxShadow: '0px 2px 4px 0px #0000001F',
                  backgroundColor: '#FEFEFF',
                  color: 'black',
                  borderRadius: '10px',
                },
              },
            }}
            open={openAssigneesDropdown}
            title={
              <React.Fragment>
                <Grid item container maxHeight='160px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                  <Grid item container direction='row' columnGap={2} marginBottom={1.5} justifyContent='center'>
                    <Grid item className={classes[assigneesType === 'Internal' ? "internal-external-active" : "internal-external-button"]}
                      onClick={() => setAssigneesType('Internal')}>Internal</Grid>
                    <Grid item className={classes[assigneesType === 'External' ? "internal-external-active" : "internal-external-button"]}
                      onClick={() => setAssigneesType('External')}>External</Grid>
                  </Grid>
                  <Grid item marginBottom={1}>
                    <TextField
                      // ref={assgineeTriggerRef}
                      value={assigneesType === "External" ? externalAssigneeSearch : internalAssigneeSearch}
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                        style: {
                          borderRadius: '35px',
                          fontFamily: 'DM Sans',
                          height: '32px',
                          backgroundColor: '#F7F9FF',
                          // border: '1px solid #E6E6E8'
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#E6E6E8", // Sets the border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#E6E6E8", // Ensures border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#E6E6E8", // Ensures border color when focused
                          },
                        },
                        "& .MuiInputBase-input::placeholder": {
                          fontWeight: "normal",
                        },
                      }}
                      placeholder={intl.formatMessage({ id: 'text.search' })}
                      // onChange={(e) => {
                      //   // e.stopPropagation();
                      //   setSearch(e.target.value);
                      //   if (e.target.value == "") {
                      //     setFilteredExternalAssignees(externalAssigneesList);
                      //     setFilteredInternalAssignees(internalAssigneesList)
                      //   }
                      //   else {
                      //     setFilteredInternalAssignees(internalAssigneesList?.filter((user: any) => user.name.toLowerCase().includes(e.target.value.toLowerCase())))
                      //     setFilteredExternalAssignees(externalAssigneesList?.filter((user: any) => user.name.toLowerCase().includes(e.target.value.toLowerCase())))
                      //   }
                      // }
                      // }
                      onChange={(e) => {
                        const searchTerm = e.target.value;
                        if (assigneesType === 'External') {
                          setExternalAssigneeSearch(searchTerm);
                          if (searchTerm.trim() === '') {
                            setFilteredExternalAssigneesList(externalAssigneesList);
                          } else {
                            const filtered = externalAssigneesList.filter((externalAssignee: any) =>
                              externalAssignee.name.toLowerCase().includes(searchTerm.toLowerCase())
                            );
                            setFilteredExternalAssigneesList(filtered);
                          }
                        } else {
                          setInternalAssigneeSearch(searchTerm);
                          if (searchTerm.trim() === '') {
                            setFilteredInternalAssigneesList(internalAssigneesList);
                          } else {
                            const filtered = internalAssigneesList.filter((internalAssignee: any) =>
                              internalAssignee.name.toLowerCase().includes(searchTerm.toLowerCase())
                            );
                            setFilteredInternalAssigneesList(filtered);
                          }
                        }
                      }}
                    />
                  </Grid>

                  <Grid item container direction="column" rowGap={1}>
                    {assigneesType === 'Internal' ? filteredInternalAssigneesList?.map((i: any, index: any) => (
                      <Grid item container alignItems='center' key={i.id} columnGap={1}
                        // className={classes[i.id === taskObject.assigneeId ? 'filter-selected' : 'filter-not-selected']}
                        //justifyContent='space-between'
                        onMouseOver={(e) => {
                          const target = e.target as HTMLElement;
                          target.style.cursor = 'pointer';
                        }}
                        onClick={(e) => {
                          taskObject.assigneeId = i.id
                          forceUpdate();
                          setOpenAssigneesDropdown(false);
                        }}
                      >
                        <Grid className='bordered-box'
                          style={{ borderColor: i.id === taskObject.assigneeId ? '#fff' : '#E6E6E8' }}
                          bgcolor={i.id === taskObject.assigneeId ? '#CCF0EB' : '#fff'}>
                          {
                            i.id === taskObject.assigneeId ?
                              // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '13px', height: '12px' }} />

                              <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                              :
                              <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                          }
                        </Grid>
                        {/* <Grid className='bordered-box' bgcolor={i.id === taskObject.assigneeId ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={i.id === taskObject.assigneeId ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                        <Grid item>{i.name}</Grid>
                      </Grid>
                    )) :
                      filteredExternalAssigneesList?.map((i: any) => (
                        <Grid item container alignItems='center'
                          // justifyContent='space-between' 
                          key={i.id} columnGap={1}
                          //className={classes[i.id === taskObject.assigneeId ? 'filter-selected' : 'filter-not-selected']}
                          onMouseOver={(e) => {
                            const target = e.target as HTMLElement;
                            target.style.cursor = 'pointer';
                          }}
                          onClick={(e) => {
                            taskObject.assigneeId = i.id
                            forceUpdate();
                            setOpenAssigneesDropdown(false);
                          }}
                        >
                          <Grid className='bordered-box'
                            style={{ borderColor: i.id === taskObject.assigneeId ? '#fff' : '#E6E6E8' }}
                            bgcolor={i.id === taskObject.assigneeId ? '#CCF0EB' : '#fff'}>
                            {
                              i.id === taskObject.assigneeId ?
                                // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '13px', height: '12px' }} />

                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                          </Grid>
                          {/* <Grid className='bordered-box' bgcolor={i.id === taskObject.assigneeId ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={i.id === taskObject.assigneeId ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                          <Grid item>{i.name}</Grid>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          >
            <Grid item container justifyContent='space-between'
              alignItems='center' textAlign='center' width='100%'
              className='fieldDesign' style={{ border: 'solid 1px #C3C2C7', cursor: 'pointer' }}
              paddingRight={1}
              paddingLeft={1.5}
              onClick={() => setOpenAssigneesDropdown(!openAssigneesDropdown)}>
              <Grid item>
                {
                  internalAssigneesList?.find((i: any) => i.id === taskObject.assigneeId)?.name ||
                  externalAssigneesList?.find((i: any) => i.id === taskObject.assigneeId)?.name ||
                  <div style={{ display: 'inline-flex', color: '#C3C2C7' }}><Message id="task.assignee" className='Field-input font-weight-400 mobile-paragraph' /> <span style={{ color: 'red', marginLeft: '5px' }}>*</span></div>
                }
              </Grid>
              <Grid item textAlign='right'><ArrowDropDownIcon htmlColor='#6F7E8C' /></Grid>
            </Grid>

          </Tooltip>
          {/* <Grid item width='100%'>
               <TextField
                fullWidth
                select
                value={taskObject.assigneeId}
                onChange={(e) => {
                  taskObject.assigneeId = e.target.value
                  forceUpdate();
                }}
                sx={{
                  '& label.Mui-focused': {
                    color: '#C3C2C7',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#B2BAC2',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#C3C2C7',
                    },
                    '&:hover fieldset': {
                      borderColor: '#B2BAC2',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#6F7E8C',
                    },
                  },
                }}
                InputProps={{ className: 'fieldDesign' }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 150, // Set the max height of the dropdown
                      },
                    },
                  },
                }}
              >
                {assigneesList?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField> 
            </Grid> */}
        </Grid>
        {/* 
        </Grid> */}

        <Grid item container direction='column' xs={12}>
          <Grid item><Message id="task.priority" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
          <TextField
            fullWidth
            select
            value={taskObject?.priority}
            defaultValue={3}
            onChange={(e) => {
              taskObject.priority = e.target.value
              forceUpdate();
            }}
            sx={{
              '& label.Mui-focused': {
                color: '#C3C2C7',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#B2BAC2',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#C3C2C7',
                },
                '&:hover fieldset': {
                  borderColor: '#B2BAC2',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#6F7E8C',
                },
              },
            }}
            InputProps={{ className: 'fieldDesign' }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {

                    maxHeight: 150, // Set the max height of the dropdown
                  },
                },
              },
            }}
          >
            {prioritiesList?.map((option: any) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>


        </Grid>
        {/* <Grid item className='neutral-9 font-weight-400 '>
        <Message id="task.recurring-task-info" className='Field-input font-weight-400 mobile-paragraph' />
        </Grid>
        <Grid item container direction="row" columnGap={2} alignItems='center'>
          <Grid item className='labels'><Message id="task.recurring-task" className='Field-input font-weight-400 font-14' /></Grid>
          <Grid item>
            <FormControlLabel
              checked={iseRecuringTask}
              control={<IOSSwitch sx={{ m: 1 }}
                onChange={handleRecuringChange}
              />}
              label=""
            />
          </Grid>
          {iseRecuringTask ?
            <Grid item width='225px'>
              <Grid item width='225px'>
                <TextField
                  fullWidth
                  select
                  value={taskObject?.recurringPeriod || 0} // Ensure default value is 0
                  onChange={(e) => {
                    taskObject.recurringPeriod = parseInt(e.target.value);
                    forceUpdate();
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: 'white',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: 'white',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'white',
                      },
                      '&:hover fieldset': {
                        borderColor: 'white',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'white',
                      },
                    },
                    // Apply conditional styling based on selected value
                    '& .MuiSelect-select': {
                      color: taskObject?.recurringPeriod === 0 ? 'gray' : 'black',
                    },
                  }}
                  InputProps={{ className: 'fieldDesign' }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 150, // Set the max height of the dropdown
                        },
                      },
                    },
                  }}
                >
                  <MenuItem
                    key={0}
                    value={0}
                    disabled
                    sx={{
                      color: 'gray',
                    }}
                  >
                   <Message id="task.recurring-period" className='Field-input font-weight-700 labelsLinks' />
                  </MenuItem>
                  {recurringPeriodsList?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>


            </Grid>
            :
            <></>
          }

        </Grid> */}
        <Grid item container direction="row" columnSpacing={2}>
          <Grid item xs={6}>
            <button
              className={callStatus === 'Pass' && selectedButton === 1 ? 'greenButton' : callStatus === 'Fail' && selectedButton === 1 ? 'redButton' : 'greyButton'}
              disabled={loading || callStatus === 'Pass' || callStatus === 'Fail' || !taskObject.title || !taskObject.type || !taskObject.status || !taskObject.dueDate || (localStorage.getItem('userType') === '1' && !taskObject.clientId) || !taskObject.assigneeId || startError || dueError}
              onClick={() => {
                setSelectedButton(1);
                confirmAction(taskObject, 1)
              }
              }>
              {selectedButton === 1 && loading ? <span><CircularProgress style={{ color: 'grey', width: '15px', height: '15px' }} /></span>
                : selectedButton === 1 && callStatus === 'Pass' ? <CheckCircleOutlinedIcon />
                  : selectedButton === 1 && callStatus === 'Fail' ? "Failed"
                    :
                    <>
                      <LocalHospitalOutlinedIcon fontSize='small' /><Message id="task.create-task" className='Field-input font-weight-700 font-14' /></>
              }</button>
          </Grid>
          <Grid item xs={6}>
            <button
              className={callStatus === 'Pass' && selectedButton === 2 ? 'greenButton' : callStatus === 'Fail' && selectedButton === 2 ? 'redButton' : 'blueButton'}
              disabled={loading || callStatus === 'Pass' || callStatus === 'Fail' || !taskObject.title || !taskObject.type || !taskObject.status || !taskObject.dueDate || (localStorage.getItem('userType') === '1' && !taskObject.clientId) || !taskObject.assigneeId || startError || dueError}
              onClick={() => {
                setSelectedButton(2);
                confirmAction(taskObject, 2)
              }}>
              {selectedButton === 2 && loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                : selectedButton === 2 && callStatus === 'Pass' ? <CheckCircleOutlinedIcon />
                  : selectedButton === 2 && callStatus === 'Fail' ? "Failed"
                    :
                    <>
                      <LocalHospitalOutlinedIcon fontSize='small' /><Message id="button.create-and-open-task" className='Field-input font-weight-700 labelsLinks' /></>
              }</button>
          </Grid>
        </Grid>

        {errorMsg ?
          <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
          :
          <></>}
      </Grid>
    </Dialog>
  )
}

export default CreateTask