import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Backdrop, Grid, TextField } from '@mui/material';
import { getDialogLoadingState, setDialogLoading } from '../../app/slices/loadingSlice';
import CircularProgress from '@mui/material/CircularProgress';
import TeamMembersTable from '../../components/Dashboard/ManageUsers/TeamMembersTable';
import ClientsTable from '../../components/Dashboard/ManageUsers/ClientsTable';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { getModifyValue, setModify } from '../../app/slices/modifySlice';
import * as UserServices from "../../services/user-services.proxy";
import * as ClientServices from "../../services/client-services.proxy";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { getErrorMsg, setErrorMsg } from '../../app/slices/apiCallSlice';
import Message from '../../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';
import { selectRoles, selectUserType } from '../../app/slices/userDataSlice';
import * as userRolesConst from "../../user-roles-consts";
import * as rolesServices from "../../services/roles-services.proxy";
import HomeIcon from "../../assets/images/icons/homeIcon.png";

const getTMUsersList = UserServices.getTMUsersList;
const getClientsList = ClientServices.getInvitedClients;
const getUserRoles = rolesServices.getUserRoles;


const ManageUsers = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //query params
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const queryActiveTab = query.get('type');

  const [activeTab, setActiveTab] = useState<string>(queryActiveTab && parseInt(queryActiveTab) === 2 ? 'Clients' : 'Team');
  const [search, setSearch] = useState<string>();
  const [searchInput, setSearchInput] = useState<string>();

  const intl = useIntl();
  const [take, setTake] = useState(10);

  //select states 
  const modify = useAppSelector(getModifyValue);
  const dialogLoading = useAppSelector(getDialogLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const userType = useAppSelector(selectUserType);

  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [isOpenRoles, setIsOpenRoles] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState<null | 'status' | 'roles'>(null);

  const [clickedFirstNames, setClickedFirstNames] = useState<string[]>([]);
  const [selectedFirstNames, setSelectedFirstNames] = useState<string[]>([]);
  const [firstNamesList, setFirstNamesList] = useState([]);

  const [clickedLastNames, setClickedLastNames] = useState<string[]>([]);
  const [selectedLastNames, setSelectedLastNames] = useState<string[]>([]);
  const [lastNamesList, setLastNamesList] = useState([]);

  const [clickedUserNames, setClickedUserNames] = useState<string[]>([]);
  const [selectedUserNames, setSelectedUserNames] = useState<string[]>([]);
  const [userNamesList, setUserNamesList] = useState([]);

  const [clickedEmails, setClickedEmails] = useState<string[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [emailsList, setEmailsList] = useState([]);

  const [clickedRoles, setClickedRoles] = useState<number[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<any>([]);
  const [rolesList, setRolesList] = useState([]);
  const [allRoles, setAllRoles] = useState();

  const [clickedLastActiveDates, setClickedLastActiveDates] = useState<string[]>([]);
  const [selectedLastActiveDates, setSelectedLastActiveDates] = useState<string[]>([]);
  const [lastActiveDatesList, setLastActiveDatesList] = useState([]);

  const [clickedStatus, setClickedStatus] = useState<number[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<number[]>([]);
  const [statusesList, setStatusesList] = useState<any>([]);

  const [clickedPhoneNumbers, setClickedPhoneNumbers] = useState<string[]>([]);
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<string[]>([]);
  const [phoneNumbersList, setPhoneNumbersList] = useState([]);

  const [clickedCountries, setClickedCountries] = useState<string[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [countriesList, setCountriesList] = useState([]);

  const [clickedCreationDates, setClickedCreationDates] = useState<string[]>([]);
  const [selectedCreationDates, setSelectedCreationDates] = useState<string[]>([]);
  const [creationDatesList, setCreationDatesList] = useState([]);

  //data to fill
  const [teamMembersTable, setTeamMembersTable] = useState<any>([]);
  const [originalTeamMembersTable, setOriginalTeamMembersTable] = useState<any>([]);
  const [clientsTable, setClientsTable] = useState<any[]>([]);
  const [originalClientsTable, setOriginalClientsTable] = useState<any>([]);
  const [dataLength, setDataLength] = useState(0);
  const [roles, setRoles] = useState([]);

  const [initiallistLoading, setInitialListLoading] = useState(false);

  // const [tmPaginationModel, setTmPaginationModel] = useState({ page: 0, pageSize: 5 });
  // const [clientPaginationModel, setClientPaginationModel] = useState({ page: 0, pageSize: 5 });

  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [loadMoreRows, setLoadMoreRows] = useState(false);
  const [oldRows, setOldRows] = useState<any>([]);
  const [noMoreRows, setNoMoreRows] = useState(false);
  const [notLoad, setNotLoad] = useState(false);

  const userRoles = useAppSelector(selectRoles);

  useEffect(() => {
    dispatch(setDialogLoading(true));
    getUserRoles().then((x) => {
      const tmp = x?.filter((r: { typeId: number; }) => (localStorage.getItem('userType') === '1' ? r.typeId === 1 : r.typeId === 2));
      setAllRoles(tmp);
      dispatch(setDialogLoading(false));
    })

  }, [])

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchInput(value);

      const debounceTimeout = setTimeout(() => {
        setOldRows([]);
        setNoMoreRows(false)
        setSearch(value);
      }, 500);

      return () => clearTimeout(debounceTimeout);
    },
    [setOldRows, setSearch]
  );

  const handleTabChange = (newTab: string) => {
    if (newTab !== activeTab) {
      setOldRows([]);
      setNoMoreRows(false);
      setSearch('');
      setSearchInput('');
      setSelectedStatuses([]);
      setSelectedRoles([]);
      setClickedStatus([]);
      setClickedRoles([]);
      setActiveTooltip(null);
      setDataLength(0);
      setTake(10)

      if (newTab === 'Team') {
        // setTmPaginationModel({ page: 0, pageSize: 5 });
        setPaginationModel({ page: 0, pageSize: 5 });
        setTeamMembersTable([]);

      } else {
        // setClientPaginationModel({ page: 0, pageSize: 5 });
        setPaginationModel({ page: 0, pageSize: 5 });
        setClientsTable([]);
      }
      setActiveTab(newTab);
    }
  };

  // const getCurrentPaginationModel = () => {
  //   return activeTab === 'Team' ? tmPaginationModel : clientPaginationModel;
  // };

  const listData =
  {
    searchTerm: search ? search : null,
    // pageNumber: paginationModel.page + 1,
    // pageSize: paginationModel.pageSize,
    skip: oldRows?.length,
    take: take,
    roles: selectedRoles,
    statusId: activeTab === 'Clients' ? selectedStatuses : 0,
    statusArray: activeTab === 'Team' ? selectedStatuses : 0,

  }

  const gridRef = useRef<any>(null);

  const scrollToBottom = () => {
    if (gridRef.current) {

      const virtualScroller = gridRef.current.querySelector('.MuiDataGrid-virtualScroller');
      if (virtualScroller) {
        virtualScroller.scrollTop = virtualScroller.scrollHeight;
      }
    }
  };

  const scrollToTop = () => {
    if (gridRef.current) {
      const virtualScroller = gridRef.current.querySelector('.MuiDataGrid-virtualScroller');
      if (virtualScroller) {
        virtualScroller.scrollTop = 0;
      }
    }
  };

  const checkNeedToAddEmptyRow = (table: any) => {
    const gridContainer = gridRef.current;
    console.log("tesst", gridContainer)
    if (gridContainer) {
      // Get the virtual scroller container from the DataGrid
      const virtualScroller = gridContainer.querySelector(
        ".MuiDataGrid-virtualScroller"
      );
      const rowHeight = 52; // This is the row height in px, you may need to adjust based on your grid configuration.
      const totalRowsHeight = rowHeight * (table.length);

      if (totalRowsHeight >= virtualScroller.clientHeight) {
        console.log("'yes yes")
        return true
      } else {
        console.log("no no")
        return false
      }
    }
  }

  const getTeamMembersListCall = () => {
    if (!loadMoreRows) {
      dispatch(setDialogLoading(true));
      setInitialListLoading(true);
    }

    getTMUsersList(listData).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);
      } else {
        if (loadMoreRows) {
          if (x.users.length === 0 && checkNeedToAddEmptyRow(teamMembersTable)) {
            setNoMoreRows(true);
            const emptyExists = teamMembersTable?.find((r: any) => r.id === 'Empty') ? true : false
            if (!emptyExists) {
              console.log("rows", teamMembersTable)
              let newUserbj = {
                id: "Empty",
                email: null,
                firstName: null,
                lastName: null,
                phoneNumber: null,
                position: null,
                creationDate: null,
                statusId: 0,
                roles: null,
                lastActiveDate: null,
                taskCount: 0

              }
              let tmp = [...teamMembersTable];
              tmp.splice(teamMembersTable?.length, 0, newUserbj);
              setTeamMembersTable(tmp);
              setNotLoad(true);
              setTimeout(() => {
                scrollToBottom()
              }, 200)

              setTimeout(() => {
                setNotLoad(false);

              }, 4000)

            }
          }
          else {
            let old = teamMembersTable
            setOldRows(old)
            let tmp: any[] = [];
            tmp.push(...old, ...(x.users));
            setOldRows(tmp);
            setTeamMembersTable(tmp)
            console.log("concat", tmp)

          }


        }
        else {
          scrollToTop()
          setTeamMembersTable(x.users);
          setFirstNamesList(x.users?.map((u: any) => u.firstName)?.filter((val: string) => val !== null));
          setLastNamesList(x.users?.map((u: any) => u.lastName)?.filter((val: string) => val !== null));
          setEmailsList(x.users?.map((u: any) => u.email));
          const rolesExtracted = x.users?.reduce((acc: any[], user: any) => {
            user.roles.forEach((role: any) => {
              // Check if the role already exists in the accumulator (based on role.id)
              if (!acc.find((r: any) => r.id === role.id)) {
                acc.push(role);
              }
            });
            return acc;
          }, []);
          setRolesList(rolesExtracted);
          setLastActiveDatesList(x.users?.map((u: any) => u.lastActiveDate)?.filter((val: string) => val !== "" && val !== null));
          setStatusesList(
            x.users?.map((u: any) => u.statusId).reduce((acc: any, statusId: any) => {
              if (!acc.includes(statusId)) acc.push(statusId);
              return acc;
            }, [])
          )
          setPhoneNumbersList(x.users?.map((u: any) => u.phoneNumber)?.filter((val: string) => val !== "" && val !== null));
          setCreationDatesList(x.users?.map((u: any) => u.creationDate)?.filter((val: string) => val !== "" && val !== null));
          setOriginalTeamMembersTable(x.users)
          setOldRows(x.users)
        }
        setDataLength(x.total || 0);
        setRoles(x.roles);
      }
      dispatch(setDialogLoading(false));
      setInitialListLoading(false);
      setLoadMoreRows(false);
    })
  }

  const getClientsListCall = () => {
    if (!loadMoreRows) {
      dispatch(setDialogLoading(true));
    }
    getClientsList(listData).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);
      } else {
        if (loadMoreRows) {
          if (x.clients.length === 0 && checkNeedToAddEmptyRow(clientsTable)) {
            setNoMoreRows(true);
            const emptyExists = clientsTable?.find((r: any) => r.id === 'Empty') ? true : false
            if (!emptyExists) {
              let newUserbj = {
                id: "Empty",
                email: null,
                firstName: null,
                lastName: null,
                phoneNumber: null,
                position: null,
                creationDate: null,
                statusId: 0,
                roles: null,
                lastActiveDate: null,
                taskCount: 0

              }
              let tmp = [...clientsTable];
              tmp.splice(clientsTable?.length, 0, newUserbj);
              setClientsTable(tmp);
              setNotLoad(true);
              setTimeout(() => {
                scrollToBottom()
              }, 200)

              setTimeout(() => {
                setNotLoad(false);

              }, 4000)

            }

          }
          else {
            let old = clientsTable
            setOldRows(old)
            let tmp: any[] = [];
            tmp.push(...old, ...(x.clients));
            setOldRows(tmp);
            setClientsTable(tmp)
            console.log("concat", tmp)
          }


        }
        else {
          scrollToTop();
          setUserNamesList(x.clients?.map((u: any) => u.name)?.filter((val: string) => val !== null));
          setEmailsList(x.clients?.map((u: any) => u.email));
          const rolesExtracted = x.clients?.reduce((acc: any[], user: any) => {
            user.roles.forEach((role: any) => {
              // Check if the role already exists in the accumulator (based on role.id)
              if (!acc.find((r: any) => r.id === role.id)) {
                acc.push(role);
              }
            });
            return acc;
          }, []);
          setRolesList(rolesExtracted);
          setLastActiveDatesList(x.clients?.map((u: any) => u.lastActiveDate)?.filter((val: string) => val !== "" && val !== null));
          setStatusesList(
            x.clients?.map((u: any) => u.statusId).reduce((acc: any, statusId: any) => {
              if (!acc.includes(statusId)) acc.push(statusId);
              return acc;
            }, [])
          )
          setPhoneNumbersList(x.clients?.map((u: any) => u.contactPhoneNumber)?.filter((val: string) => val !== "" && val !== null));
          setCountriesList(x.clients?.map((u: any) => u.land)?.filter((val: string) => val !== "" && val !== null));
          setCreationDatesList(x.clients?.map((u: any) => u.creationDate)?.filter((val: string) => val !== "" && val !== null));
          setClientsTable(x.clients || 0);
          setOriginalClientsTable(x.clients)
          setOldRows(x.clients)
        }
        setDataLength(x.total);
        setRoles(x.roles);
      }
      dispatch(setDialogLoading(false));
      setLoadMoreRows(false);
    })
  };

  // useEffect(() => {
  //   if (activeTab === 'Team') getTeamMembersListCall();
  //   if (activeTab === 'Clients') getClientsListCall();
  // }, [modify, activeTab, search, selectedRoles, statusId, paginationModel])

  // useEffect(() => {
  //   if (loadMoreRows && activeTab === 'Team') getTeamMembersListCall()
  //   if (loadMoreRows && activeTab === 'Clients') getClientsListCall()
  // }, [loadMoreRows])


  useEffect(() => {
    setOldRows([]);
    setNoMoreRows(false);
    setSelectedStatuses([]);
    setSearch('');
    setSelectedRoles([]);
    setClickedStatus([]);
    setClickedRoles([]);
    setIsOpenRoles(false);
    setIsOpenStatus(false);
    setActiveTooltip(null);
    dispatch(setModify(!modify))
    setSearchInput('');
    setSelectedEmails([]);
    setClickedEmails([]);
    setSelectedRoles([]);
    setClickedRoles([]);
    setSelectedLastActiveDates([]);
    setClickedLastActiveDates([]);
    setSelectedStatuses([]);
    setClickedStatus([]);
    setSelectedPhoneNumbers([]);
    setClickedPhoneNumbers([]);
    setSelectedUserNames([]);
    setClickedUserNames([]);
    setSelectedFirstNames([]);
    setClickedFirstNames([]);
    setSelectedLastNames([]);
    setClickedLastNames([]);
    setSelectedCreationDates([]);
    setClickedCreationDates([]);  
    setTeamMembersTable(originalTeamMembersTable);
    setClientsTable(originalClientsTable);
    if (activeTab === 'Team') getTeamMembersListCall();
    if (activeTab === 'Clients') getClientsListCall();
  }, [activeTab])

  useEffect(() => {
    if (activeTab === 'Team') {
      const filteredTms = originalTeamMembersTable?.filter((tm: any) => {

        const searchCheck = search ?
          (tm.firstName?.toLowerCase().includes(search.toLowerCase())
            || tm.lastName?.toLowerCase().includes(search.toLowerCase())
            || tm.email?.toLowerCase().includes(search.toLowerCase())
            || tm.phoneNumber?.toLowerCase().includes(search.toLowerCase())
            || tm.position?.toLowerCase().includes(search.toLowerCase())
            || tm.roles?.find((r: any) => r.name?.toLowerCase()?.includes(search.toLowerCase()))
            || tm.email?.toLowerCase().includes(search.toLowerCase())
          ) : true;

        const firstNameCheck = selectedFirstNames.length > 0 ? selectedFirstNames.includes(tm.firstName) : true;
        const lastNameCheck = selectedLastNames.length > 0 ? selectedLastNames.includes(tm.lastName) : true;
        const emailCheck = selectedEmails.length > 0 ? selectedEmails.includes(tm.email) : true;
        const rolesCheck = selectedRoles.length > 0 ? tm.roles.some((role: any) => selectedRoles.includes(role.id)) : true;
        const lastActiveCheck = selectedLastActiveDates.length > 0 ? selectedLastActiveDates.includes(tm.lastActiveDate) : true;
        const statusCheck = selectedStatuses.length > 0 ? selectedStatuses.includes(tm.statusId) : true;
        const phoneNbCheck = selectedPhoneNumbers.length > 0 ? selectedPhoneNumbers.includes(tm.phoneNumber) : true;
        const creationDateCheck = selectedCreationDates.length > 0 ? selectedCreationDates.includes(tm.creationDate) : true;

        return searchCheck && firstNameCheck && lastNameCheck && emailCheck && rolesCheck && lastActiveCheck && statusCheck && phoneNbCheck && creationDateCheck

        // if (statusId.length > 0) {
        //   return statusId.includes(tm.statusId);  // filter by user status in statuses
        // }
        // // If statuses array is empty, return all users
        // return true;
      }
      )
      setTeamMembersTable(filteredTms)
    }
    if (activeTab === 'Clients') {
      const filteredClients = originalClientsTable?.filter((cl: any) => {

        const searchCheck = search ?
          (cl.name?.toLowerCase().includes(search.toLowerCase())
            || cl.email?.toLowerCase().includes(search.toLowerCase())
            || cl.contactName?.toLowerCase().includes(search.toLowerCase())
            || cl.contactEmail?.toLowerCase().includes(search.toLowerCase())
            || cl.contactPhoneNumber?.toLowerCase().includes(search.toLowerCase())
            || cl.land?.toLowerCase()?.includes(search.toLowerCase())
            // || cl.street?.toLowerCase().includes(search.toLowerCase())
            // || cl.postalCode?.toLowerCase().includes(search.toLowerCase())
            // || cl.houseNumber?.toLowerCase().includes(search.toLowerCase())
            || cl.roles?.find((r: any) => r.name?.toLowerCase()?.includes(search.toLowerCase()))
          ) : true;

        const userNameCheck = selectedUserNames.length > 0 ? selectedUserNames.includes(cl.name) : true;
        const emailCheck = selectedEmails.length > 0 ? selectedEmails.includes(cl.email) : true;
        const rolesCheck = selectedRoles.length > 0 ? cl.roles.some((role: any) => selectedRoles.includes(role.id)) : true;
        const lastActiveCheck = selectedLastActiveDates.length > 0 ? selectedLastActiveDates.includes(cl.lastActiveDate) : true;
        const statusCheck = selectedStatuses.length > 0 ? selectedStatuses.includes(cl.statusId) : true;
        const phoneNbCheck = selectedPhoneNumbers.length > 0 ? selectedPhoneNumbers.includes(cl.phoneNumber) : true;
        const countryCheck = selectedCountries.length > 0 ? selectedCountries.includes(cl.land) : true;
        const creationDateCheck = selectedCreationDates.length > 0 ? selectedCreationDates.includes(cl.creationDate) : true;

        return searchCheck && userNameCheck && emailCheck && rolesCheck && lastActiveCheck && statusCheck && phoneNbCheck && countryCheck && creationDateCheck

        // if (statusId.length > 0) {
        //   return statusId.includes(tm.statusId);  // filter by user status in statuses
        // }
        // // If statuses array is empty, return all users
        // return true;
      }
      )
      setClientsTable(filteredClients)

    }


  }, [search, selectedFirstNames, selectedLastNames, selectedUserNames, selectedEmails, selectedRoles, selectedLastActiveDates, selectedStatuses, selectedPhoneNumbers, selectedCreationDates, selectedCountries])


  return (
    // <>
    //   {localStorage.getItem('userType') === '1' ?
    <Grid container rowGap={0.5} width='100%' maxHeight='90vh'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={dialogLoading || initiallistLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item className='desktop-header-5' xs={12}>

        <Message id="text.admin-area" className="Field-input desktop-header-5 font-weight-400" />
      </Grid>
      <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} xs={12}>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
            else navigate('/tasks')
          }
          }
        >
          {/* <HomeOutlinedIcon style={{ color: '#93919A' }} /> */}
          <img src={HomeIcon} width='20px' height='20px' style={{ marginTop: '5px' }} />

        </span><Message id="user.profile.home" className="Field-input desktop-paragraph font-weight-400 pointerText"
          onClick={() => {
            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
            else navigate('/tasks')
          }
          } /> /
        {activeTab === 'Team' ? <Message id="user.profile.user-management" className="Field-input desktop-paragraph font-weight-400" /> : <Message id="user.profile.manage-company" className="Field-input desktop-paragraph font-weight-400" />}
        {/* <Message id="user.profile.manage-users" className="Field-input desktop-paragraph font-weight-400" /> */}

        <Grid item container marginTop={2} alignItems='center'>
          <Grid item className={activeTab === 'Team' ? 'activeTabs' : 'tabs'}
            onClick={() => handleTabChange('Team')}>
            <Message id="text.users" className="labelsLinks labels-extra-bold Field-input " />

          </Grid>
          {localStorage.getItem('userType') === '1' ?
            <Grid item className={activeTab === 'Clients' ? 'activeTabs' : 'tabs'}
              onClick={() => handleTabChange('Clients')}>
              <Message id="text.companies" className="labelsLinks labels-extra-bold Field-input " />

            </Grid>
            :
            <></>
          }

          {errorMsg ?
            <Grid item className='errorText' marginLeft={2}>{errorMsg}</Grid> :
            <></>
          }
        </Grid>
        <Grid item container bgcolor='#FEFEFF' borderRadius='0px 8px 8px 8px' boxShadow='0px 2px 4px 0px #0000001F'>
          <Grid item container direction='row' columnGap={2} padding={1}>
            <Grid item xs={5}>
              <TextField
                value={searchInput}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                  style: {
                    borderRadius: '35px',
                    fontFamily: 'DM Sans',
                    height: '48px',
                    backgroundColor: '#F7F9FF',
                    border: '1px solid #E6E6E8'
                  },
                }}
                sx={{
                  '& .MuiInputBase-input::placeholder': {
                    fontWeight: 'bold',
                  },
                }}
                placeholder={intl.formatMessage({ id: 'text.search' })}
                // onChange={(e) => {
                //   setOldRows([]);
                //   setSearch(e.target.value)}
                // }
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid item style={{ border: '1px solid #E6E6E8', borderRadius: '8px', display: 'flex', alignItems: 'center', padding: 10, cursor: 'pointer' }}
              onClick={() => {
                setOldRows([]);
                setNoMoreRows(false);
                setSelectedStatuses([]);
                setSearch('');
                setSelectedRoles([]);
                setClickedStatus([]);
                setClickedRoles([]);
                setIsOpenRoles(false);
                setIsOpenStatus(false);
                setActiveTooltip(null);
                dispatch(setModify(!modify))
                setSearchInput('');
                setSelectedEmails([]);
                setClickedEmails([]);
                setSelectedRoles([]);
                setClickedRoles([]);
                setSelectedLastActiveDates([]);
                setClickedLastActiveDates([]);
                setSelectedStatuses([]);
                setClickedStatus([]);
                setSelectedPhoneNumbers([]);
                setClickedPhoneNumbers([]);
                setSelectedUserNames([]);
                setClickedUserNames([]);
                setSelectedFirstNames([]);
                setClickedFirstNames([]);
                setSelectedLastNames([]);
                setClickedLastNames([]);
                setSelectedCreationDates([]);
                setClickedCreationDates([]);  
                setTeamMembersTable(originalTeamMembersTable)
              }
              }><RestartAltIcon htmlColor='#C3C2C7' />
            </Grid>
            <Grid item width='220px'>
              <button className='blueButton'
                style={{ height: '48px', width: '220px' }}
                onClick={() => {
                  if (activeTab === "Team") navigate('/add-users?type=1')
                  else navigate('/add-users?type=2')
                }
                }
                disabled={
                  (activeTab === "Clients" && userType === 1 && !userRoles?.includes(userRolesConst.ADMIN_ID) && !userRoles?.includes(userRolesConst.TAX_ADVISOR_ID) && !userRoles?.includes(userRolesConst.REVIEWER_ID))
                  ||
                  (activeTab === "Team" && userType === 1 && !userRoles?.includes(userRolesConst.ADMIN_ID))
                }>
                <Grid item container alignItems='center' columnGap={1} justifyContent='center'>
                  <GroupAddOutlinedIcon />
                  {activeTab === "Team" ?
                    <Message id="user.profile.add-user" className="labelsLinks labels-extra-bold Field-input " />
                    :
                    // <Message id="button.add-client" className="labelsLinks labels-extra-bold Field-input " />
                    <Message id="button.add-companies" className="labelsLinks labels-extra-bold Field-input " />
                  }
                </Grid>

              </button>
            </Grid>
          </Grid>
          {activeTab === 'Team' && (
            (userType === 1 && (userRoles?.includes(userRolesConst.ADMIN_ID) || userRoles?.includes(userRolesConst.TAX_ADVISOR_ID)))
            ||
            (userType === 2 && (userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID)))
          ) ?
            <TeamMembersTable
              rows={teamMembersTable}
              setRows={setTeamMembersTable}
              dataLength={dataLength}
              setDataLength={setDataLength}
              roles={rolesList}
              setRoles={setRolesList}
              selectedRoles={selectedRoles}
              setSelectedRoles={setSelectedRoles}
              modify={modify}
              setModify={setModify}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              loadMoreRows={loadMoreRows}
              setLoadMoreRows={setLoadMoreRows}
              setOldRows={setOldRows}
              // pageNb={pageNb}
              // setPageNb={setPageNb}
              // pageSize={pageSize}
              // setPageSize={setPageSize}
              isOpenStatus={isOpenStatus}
              setIsOpenStatus={setIsOpenStatus}
              activeTooltip={activeTooltip}
              setActiveTooltip={setActiveTooltip}
              clickedStatus={clickedStatus}
              setClickedStatus={setClickedStatus}
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
              setTake={setTake}
              initiallistLoading={initiallistLoading}
              dialogLoading={dialogLoading}
              noMoreRows={noMoreRows}
              setNoMoreRows={setNoMoreRows}
              gridRef={gridRef}
              notLoad={notLoad}
              setNotLoad={setNotLoad}
              clickedEmails={clickedEmails}
              setClickedEmails={setClickedEmails}
              selectedEmails={selectedEmails}
              setSelectedEmails={setSelectedEmails}
              emailsList={emailsList}
              clickedFirstNames={clickedFirstNames}
              setClickedFirstNames={setClickedFirstNames}
              selectedFirstNames={selectedFirstNames}
              setSelectedFirstNames={setSelectedFirstNames}
              firstNamesList={firstNamesList}
              clickedLastNames={clickedLastNames}
              setClickedLastNames={setClickedLastNames}
              selectedLastNames={selectedLastNames}
              setSelectedLastNames={setSelectedLastNames}
              lastNamesList={lastNamesList}
              allRoles={allRoles}
              originalRows={originalTeamMembersTable}
              setOriginalRows={setOriginalTeamMembersTable}
              clickedLastActiveDates={clickedLastActiveDates}
              setClickedLastActiveDates={setClickedLastActiveDates}
              selectedLastActiveDates={selectedLastActiveDates}
              setSelectedLastActiveDates={setSelectedLastActiveDates}
              lastActiveDatesList={lastActiveDatesList}
              clickedPhoneNumbers={clickedPhoneNumbers}
              setClickedPhoneNumbers={setClickedPhoneNumbers}
              selectedPhoneNumbers={selectedPhoneNumbers}
              setSelectedPhoneNumbers={setSelectedPhoneNumbers}
              phoneNumbersList={phoneNumbersList}
              clickedCreationDates={clickedCreationDates}
              setClickedCreationDates={setClickedCreationDates}
              selectedCreationDates={selectedCreationDates}
              setSelectedCreationDates={setSelectedCreationDates}
              creationDatesList={creationDatesList}
              statusesList={statusesList}
              setOriginalTeamMembersTable={setOriginalTeamMembersTable}

            />
            :
            <></>
          }
          {activeTab === 'Clients' && localStorage.getItem('userType') === '1' ?
            <ClientsTable
              rows={clientsTable}
              setRows={setClientsTable}
              dataLength={dataLength}
              setDataLength={setDataLength}
              roles={rolesList}
              setRoles={setRolesList}
              selectedRoles={selectedRoles}
              setSelectedRoles={setSelectedRoles}
              modify={modify}
              setModify={setModify}
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              clickedStatus={clickedStatus}
              setClickedStatus={setClickedStatus}
              clickedRoles={clickedRoles}
              setClickedRoles={setClickedRoles}
              activeTooltip={activeTooltip}
              setActiveTooltip={setActiveTooltip}
              isOpenRoles={isOpenRoles}
              setIsOpenRoles={setIsOpenRoles}
              isOpenStatus={isOpenStatus}
              setIsOpenStatus={setIsOpenStatus}
              loadMoreRows={loadMoreRows}
              setLoadMoreRows={setLoadMoreRows}
              setOldRows={setOldRows}
              // pageNb={pageNb}
              // setPageNb={setPageNb}
              // pageSize={pageSize}
              // setPageSize={setPageSize}
              setTake={setTake}
              initiallistLoading={initiallistLoading}
              dialogLoading={dialogLoading}
              noMoreRows={noMoreRows}
              setNoMoreRows={setNoMoreRows}
              gridRef={gridRef}
              notLoad={notLoad}
              setNotLoad={setNotLoad}
              clickedUserNames={clickedUserNames}
              setClickedUserNames={setClickedUserNames}
              selectedUserNames={selectedUserNames}
              setSelectedUserNames={setSelectedUserNames}
              userNamesList={userNamesList}
              clickedEmails={clickedEmails}
              setClickedEmails={setClickedEmails}
              selectedEmails={selectedEmails}
              setSelectedEmails={setSelectedEmails}
              emailsList={emailsList}
              clickedLastActiveDates={clickedLastActiveDates}
              setClickedLastActiveDates={setClickedLastActiveDates}
              selectedLastActiveDates={selectedLastActiveDates}
              setSelectedLastActiveDates={setSelectedLastActiveDates}
              lastActiveDatesList={lastActiveDatesList}
              statusesList={statusesList}
              clickedPhoneNumbers={clickedPhoneNumbers}
              setClickedPhoneNumbers={setClickedPhoneNumbers}
              selectedPhoneNumbers={selectedPhoneNumbers}
              setSelectedPhoneNumbers={setSelectedPhoneNumbers}
              phoneNumbersList={phoneNumbersList}

              clickedCountries={clickedCountries}
              setClickedCountries={setClickedCountries}
              selectedCountries={selectedCountries}
              setSelectedCountries={setSelectedCountries}
              countriesList={countriesList}

              clickedCreationDates={clickedCreationDates}
              setClickedCreationDates={setClickedCreationDates}
              selectedCreationDates={selectedCreationDates}
              setSelectedCreationDates={setSelectedCreationDates}
              creationDatesList={creationDatesList}
              setOriginalClientsTable={setOriginalClientsTable}
            />
            :
            <></>}
        </Grid>

      </Grid>
    </Grid>

    //     :


    //     <Grid container direction='column' rowGap={1} width='100%'>
    //       <Backdrop
    //         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //         open={dialogLoading || initiallistLoading}>
    //         <CircularProgress color="inherit" />
    //       </Backdrop>
    //       <Grid item className='desktop-header-5'><Message id="user.profile.manage-user" className="Field-input desktop-header-5 font-weight-400" /></Grid>
    //       <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} >
    //         <HomeOutlinedIcon style={{ color: '#93919A' }} /> <Message id="user.profile.home" className="Field-input desktop-paragraph font-weight-400" /> / <Message id="user.profile.user-management" className="Field-input desktop-paragraph font-weight-400" /> / <Message id="user.profile.manage-users" className="Field-input desktop-paragraph font-weight-400" />
    //         <Grid item container marginTop={2} alignItems='center'>
    //           <Grid item className='activeTabs'>
    //             <Message id="button.team" className="labelsLinks labels-extra-bold Field-input " />
    //           </Grid>
    //           {errorMsg ?
    //             <Grid item className='errorText' marginLeft={2}>{errorMsg}</Grid> :
    //             <></>
    //           }
    //         </Grid>
    //         <Grid item container bgcolor='#FEFEFF' borderRadius='0px 8px 8px 8px' boxShadow='0px 2px 4px 0px #0000001F'>
    //           <Grid item container direction='row' columnGap={2} padding={1}>
    //             <Grid item xs={5}>
    //               <TextField
    //                 value={search}
    //                 fullWidth
    //                 InputProps={{
    //                   startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
    //                   style: {
    //                     borderRadius: '35px',
    //                     fontFamily: 'DM Sans',
    //                     height: '48px',
    //                     backgroundColor: '#F7F9FF',
    //                     border: '1px solid #E6E6E8'
    //                   },
    //                 }}
    //                 sx={{
    //                   '& .MuiInputBase-input::placeholder': {
    //                     fontWeight: 'bold',
    //                   },
    //                 }}
    //                 placeholder={intl.formatMessage({ id: 'text.search' })}
    //                 onChange={(e) => setSearch(e.target.value)}
    //               />
    //             </Grid>
    //             <Grid item style={{ border: '1px solid #E6E6E8', borderRadius: '8px', display: 'flex', alignItems: 'center', padding: 10, cursor: 'pointer' }}
    //               onClick={() => {
    //                 setStatusId(null);
    //                 setSearch('');
    //                 setSelectedRoles([]);
    //                 dispatch(setModify(!modify))
    //               }
    //               }><RestartAltIcon htmlColor='#C3C2C7' />
    //             </Grid>
    //             <Grid item width='200px'>
    //               <button className='blueButton'
    //                 onClick={() => {
    //                   navigate('/add-users?type=1')
    //                 }
    //                 }>
    //                 <Grid item container alignItems='center' columnGap={1} justifyContent='center'>
    //                   <GroupAddOutlinedIcon />
    //                   <Message id="button.add-team-member" className="labelsLinks labels-extra-bold Field-input " />
    //                 </Grid>

    //               </button>
    //             </Grid>
    //           </Grid>

    //           <TeamMembersTable
    //             rows={teamMembersTable}
    //             dataLength={dataLength}
    //             setDataLength={setDataLength}
    //             roles={roles}
    //             selectedRoles={selectedRoles}
    //             setSelectedRoles={setSelectedRoles}
    //             modify={modify}
    //             setModify={setModify}
    //             paginationModel={paginationModel}
    //             setPaginationModel={setPaginationModel}
    //           // pageNb={pageNb}
    //           // setPageNb={setPageNb}
    //           // pageSize={pageSize}
    //           // setPageSize={setPageSize}
    //           />
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   }
    // </>
  )
}

export default ManageUsers