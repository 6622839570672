import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Message from '../../sharedComponents/ui/Message';
import CheckIcon from '@mui/icons-material/Check';
import CheckedIcon from '../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../assets/images/icons/UncheckedIcon.png';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import * as userServices from "../../services/user-services.proxy";
import { getLoadingState, setLoading } from '../../app/slices/loadingSlice';
import { getCallStatus, setCallStatus, setErrorMsg } from '../../app/slices/apiCallSlice';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const updateNotificationSetup = userServices.updateNotificationSetup;
const getUserData = userServices.getUserData;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));


export interface DialogProps {
    openManageNotificationDialog: boolean;
    setOpenManageNotificationDialog: CallableFunction;
    confirmAction: any;
}

const ManageNotificationDialog = (props: DialogProps) => {

    const { openManageNotificationDialog, setOpenManageNotificationDialog, confirmAction } = props;


    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch();

    const [, updateState] = React.useState({});

    const [loadingData, setLoadingData] = useState(false);
    const callStatus = useAppSelector(getCallStatus);
    const loading = useAppSelector(getLoadingState);


    const forceUpdate = React.useCallback(() => {
        // Updating state with an empty object to trigger a re-render
        updateState({});
    }, []);

    const [notificationTypes, setNotificationTypes] = useState<any>([]);
    const [isOn, setIsOn] = useState(notificationTypes[notificationTypes.length - 1]?.value);

    const handleMuteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        notificationTypes[notificationTypes.length - 1].value = event.target.checked;
        setIsOn(event.target.checked);
        forceUpdate();
    };

    const handleClickOpen = () => {
        setOpenManageNotificationDialog(true);
        setOpen(true);
        setLoadingData(true);
        getUserData().then((x) => {
            setNotificationTypes(x.notificationSetup);
            setLoadingData(false);
        })

    };

    const handleClose = () => {
        setOpen(false);
        setOpenManageNotificationDialog(false);
    };

    useEffect(() => {
        if (openManageNotificationDialog) {
            handleClickOpen();
        }
        else {
            setOpen(false);
        }
    }, [openManageNotificationDialog]);




    // const notificationTypes = [
    //     {
    //         key: 'A',
    //         value: true,
    //     },
    //     {
    //         key: 'B',
    //         value: true
    //     },
    //     {
    //         key: 'C',
    //         value: true
    //     },
    //     {
    //         key: 'D',
    //         value: true
    //     },
    //     {
    //         key: 'E',
    //         value: true
    //     },
    //     {
    //         key: 'F',
    //         value: true
    //     },
    //     {
    //         key: 'G',
    //         value: true
    //     },
    //     {
    //         key: 'H',
    //         value: true
    //     },
    //     {
    //         key: 'I',
    //         value: true
    //     },
    //     {
    //         key: 'J',
    //         value: true
    //     },
    //     {
    //         key: 'K',
    //         value: false
    //     }
    // ]

    const updateNotification = () => {
        dispatch(setLoading(true));
        updateNotificationSetup(notificationTypes).then((x) => {
            if (x.ErrorMessage) {
                dispatch(setErrorMsg(x.ErrorMessage));
                dispatch(setCallStatus('Fail'));
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    dispatch(setCallStatus(''))
                }, 4500)
            }
            else {
                dispatch(setCallStatus('Pass'));
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    dispatch(setCallStatus(''));

                }, 1000)
            }
            dispatch(setLoading(false));
        })
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ sx: { borderRadius: "15px",maxWidth:'none'} }}

        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingData}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item container direction='column' padding={2} justifyContent='center' rowGap={1}>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item>
                        <Message id="text.manage-notification" className="neutral-9 desktop-header-6 font-weight-400" />
                    </Grid>
                    <Grid item>
                        <CloseIcon style={{ cursor: 'pointer' }} onClick={() => handleClose()} />
                    </Grid>
                </Grid>

                <Grid item className='greyLine' width='100%'></Grid>

                <Grid container direction='column' rowGap={1.5}>
                    {notificationTypes?.map((t: any, index: any) => (
                        <>
                            {index == 0 ?
                                <Message id="notification.message-related" className='font-14 neutral-9 font-weight-400 ' />
                                :
                                index === 3 ?
                                    <Message id="notification.document-related" className='font-14 neutral-9 font-weight-400 ' />
                                    :
                                    index === 5 ?
                                        <Message id="notification.task-related" className='font-14 neutral-9 font-weight-400 ' />
                                        :
                                        ""
                            }
                            <Grid container direction='row' key={index} columnGap={1}>
                                {index !== notificationTypes.length - 1 ?
                                    <>
                                        <Grid className='bordered-box'
                                            style={{ borderColor: t.value === true ? '#fff' : '#E6E6E8', cursor: notificationTypes[notificationTypes.length - 1].value === true ? 'unset' : 'pointer' }}
                                            bgcolor={t.value === true ? '#CCF0EB' : '#fff'}
                                            onClick={() => {
                                                if (notificationTypes[notificationTypes.length - 1].value === false) {
                                                    if (t.value === false) {
                                                        console.log("false")
                                                        t.value = true;
                                                        forceUpdate();
                                                    }
                                                    else {
                                                        console.log("true")
                                                        t.value = false;
                                                        forceUpdate();
                                                    }
                                                }
                                            }}
                                        >
                                            {
                                                (t.value === true && !isOn) ?
                                                    <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                                    :
                                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                            }
                                        </Grid>

                                        <Grid item>
                                            {t.key === 'A' ?
                                                <Message id="notification.new-message-mentioned" className='font-14 black' />
                                                : t.key === 'B' ?
                                                    <Message id="notification.new-message-in-assigned-task" className='font-14 black normalFontWeight' />
                                                    : t.key === 'C' ?
                                                        <Message id="notification.new-message-in-created-task" className='font-14 black normalFontWeight' />

                                                        : t.key === 'D' ?
                                                            <Message id="notification.doc-upload-in-assigned-task" className='font-14 black normalFontWeight' />

                                                            : t.key === 'E' ?
                                                                <Message id="notification.doc-upload-in-created-task" className='font-14 black normalFontWeight' />

                                                                : t.key === 'F' ?
                                                                    <Message id="notification.task-is-overdue" className='font-14 black normalFontWeight' />

                                                                    : t.key === 'G' ?
                                                                        <Message id="notification.task-overdue-tomorrow" className='font-14 black normalFontWeight' />

                                                                        : t.key === 'H' ?
                                                                            <Message id="notification.ntask-assigned-to-me" className='font-14 black normalFontWeight' />
                                                                            : t.key === 'I' ?
                                                                                <Message id="notification.task-completed" className='font-14 black normalFontWeight' />
                                                                                : t.key === 'J' ?
                                                                                    <Message id="notification.task-reopen" className='font-14 black normalFontWeight' />
                                                                                    :
                                                                                    ""
                                            }
                                        </Grid>
                                    </>
                                    :
                                    <Grid container marginTop={1.5} direction='row' columnGap={1} alignItems='center'>

                                        <Message id="notification.mute" className='font-14 black' />
                                        <FormControlLabel
                                            checked={isOn}
                                            control={<IOSSwitch sx={{ m: 1 }}
                                                onChange={handleMuteChange}
                                            />}
                                            label=""
                                        />
                                    </Grid>
                                }

                            </Grid>
                        </>

                    ))}
                </Grid>

                <Grid item container direction='row' justifyContent='flex-end' columnGap={2} paddingTop={1}>
                    <Grid item >
                        <button className='greyButton' onClick={() => handleClose()}>
                            <Message id="button.cancel" className='labelsLinks labels-extra-bold Field-input ' />
                        </button>
                    </Grid>
                    <Grid item >
                        <button
                            disabled={loading || callStatus === 'Pass' || callStatus === 'Fail'}
                            className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}

                            onClick={() => updateNotification()}>
                            {loading ?
                                <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                : callStatus === 'Pass' ?
                                    <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                    : callStatus === 'Fail' ? <Message id='text.failed' />
                                        :
                                        <Message id="button.apply-changes" className='labelsLinks labels-extra-bold Field-input ' />
                            }

                        </button></Grid>

                </Grid>
            </Grid>
        </Dialog>
    )
}

export default ManageNotificationDialog
