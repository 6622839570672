import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Backdrop, Grid, TextField, Typography } from '@mui/material';
import { getDialogLoadingState, getLoadingState, setDialogLoading, setLoading } from '../../../app/slices/loadingSlice';
import CircularProgress from '@mui/material/CircularProgress';
import TasksTable from '../../../components/Dashboard/ManageTasks/TasksTable';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeIcon from "../../../assets/images/icons/homeIcon.png";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { getModifyValue, setModify } from '../../../app/slices/modifySlice';
import * as TaskServices from "../../../services/task-services.proxy";
import * as UserServices from "../../../services/user-services.proxy";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { getCallStatus, getErrorMsg, setActionCallFrom, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import Message from '../../../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import TaskCard from '../../../sharedComponents/ui/TaskCard';
import Sun from '@mui/icons-material/WbSunnyOutlined';
import Fire from '@mui/icons-material/LocalFireDepartmentOutlined';
import Calendar from '@mui/icons-material/EventRepeatOutlined';
import Bookmark from '@mui/icons-material/BookmarkAddedOutlined';
import CreateTask from '../../../components/Dashboard/ManageTasks/CreateTask';
import { selectRoles, selectUserType } from '../../../app/slices/userDataSlice';
import * as userRolesConst from "../../../user-roles-consts";
import FilterTask from '../../../components/Dashboard/ManageTasks/FilterTask';
import * as ReminderServices from "../../../services/reminder-services.proxy";
import * as taskConsts from "../../../task-consts";
import * as statusesConsts from "../../../status-consts";
import moment from 'moment';

const getTaskList = TaskServices.getTaskList;
const getUserProfile = UserServices.getUserProfile;
const addTask = TaskServices.addTask;
const getTaskInputData = TaskServices.getTaskInputData;
const getTaskListInputData = TaskServices.getTaskListInputData;
const updateTaskStatus = TaskServices.updateTaskStatus;

const postReminder = ReminderServices.postReminder;
const inActiveReminder = ReminderServices.inActiveReminder;

const ManageTasks = ({ openDrawer }: { openDrawer: any }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const token = useAppSelector((state) => state.userData.token);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };


  //////    filters 
  const [selectedTaskName, setSelectedTaskName] = useState<string[]>([]);
  const [clickedTaskName, setClickedTaskName] = useState<string[]>([]);
  const [taskName, setTaskName] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState<number[]>([]);
  const [clickedStatus, setClickedStatus] = useState<number[]>([]);
  const [statusesList, setStatusesList] = useState<any>();

  // const [selectedType, setSelectedType] = useState(filterPayload.typeId);
  const [selectedType, setSelectedType] = useState<any>([]);
  const [clickedType, setClickedType] = useState<number[]>([]);
  const [typesList, setTypesList] = useState<any>();
  const [allTypesList, setAllTypesList] = useState<any>([]);

  // const [selectedClient, setSelectedClient] = useState<string[]>(filterPayload.clientId);
  const [selectedClient, setSelectedClient] = useState<string[]>([]);
  const [clickedClient, setClickedClient] = useState<string[]>([]);
  const [clientsList, setClientsList] = useState<any>();

  const [clickedAssignee, setClickedAssignee] = useState<any>([]);
  const [assigneesList, setAssigneesList] = useState<any>();
  const [internalAssigneesList, setInternalAssigneesList] = useState<any>([]);
  const [externalAssigneesList, setExternalAssigneesList] = useState<any>([]);

  const [recurringTaskList, setRecurringTaskList] = useState<any>([]);
  const [clickedRecurring, setClickedRecurring] = useState<string>('');
  const [selectedRecurring, setSelectedRecurring] = useState<string>('');
  const [isRecurring, setIsRecurring] = useState<boolean | null>(null);

  const [clickedPeriod, setClickedPeriod] = useState<number[]>([]);
  const [recurringPeriodsList, setRecurringPeriodsList] = useState<any>();
  const [allRecurringList, setAllRecurringList] = useState<any>([]);

  const [clickedPriority, setClickedPriority] = useState<number[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<any>([]);
  const [priorityList, setPriorityList] = useState<any>([]);
  const [allPrioritiesList, setAllPrioritiesList] = useState<any>([1, 2, 3, 4, 5]);


  const [clickedModule, setClickedModule] = useState<number[]>([]);
  const [moduleList, setModuleList] = useState<any>();
  // const [selectedModule, setSelectedModule] = useState<number[]>(filterPayload.module);
  const [selectedModule, setSelectedModule] = useState<any[]>([]);
  const [allModulesList, setAllModulesList] = useState<any>([]);

  ///////////////////////////////


  const intl = useIntl();
  const [activeTooltip, setActiveTooltip] = useState<null | 'name' | 'prio' | 'status' | 'type' | 'client' | 'assignee' | 'recurring' | 'recurringTask' | 'module'>(null);
  //select states 
  const modify = useAppSelector(getModifyValue);
  const dialogLoading = useAppSelector(getDialogLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const loading = useAppSelector(getLoadingState);
  const callStatus = useAppSelector(getCallStatus);
  const [initiallistLoading, setInitialListLoading] = useState(false);
  const [inputDataLoading, setInputDataLoading] = useState(false);
  const [openOrCompleteLoading, setOpenOrCompleteLoading] = useState(false);
  const userRoles = useAppSelector(selectRoles);
  const userType = useAppSelector(selectUserType);

  //Data to fill 
  const [userData, setUserData] = useState<any>();
  const [tasksTable, setTasksTable] = useState<any>([]);
  const [originalTasksTable, setOriginalTasksTable] = useState<any>([]);
  const [dataLength, setDataLength] = useState(0);

  const [searchTypesList, setSearchTypesList] = useState<any>();
  const [remindersList, setRemindersList] = useState<any>();

  //Add Task
  const [openAddTaskDialog, setOpenAddTaskDialog] = useState(false);
  const [originalTaskId, setOriginalTaskId] = useState(null)
  const [taskObject, setTaskObject] = useState({
    title: "",
    dueDate: "",
    startDate: "",
    description: "",
    status: 1,
    type: 1,
    module: 0,
    clientId: userType === 2 && clientsList?.length > 0 ? clientsList[0].id : null,
    assigneeId: "",
    priority: 3,
    recurringPeriod: 0,
    note: ""
  })

  //table filter states
  const [search, setSearch] = useState<string>();
  const [searchInput, setSearchInput] = useState<string>();
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [filterDialog, setFilterDialog] = useState(false);
  // const [pageNb, setPageNb] = useState(1);
  // const [pageSize, setPageSize] = useState(5);
  const [isOpenCardSelected, setIsOpenCardSelected] = useState(false);
  const [openStatusesIds, setOpenStatusesIds] = useState([1, 2, 3, 4, 6, 7])
  const [selectedPriority, setSelectedPriority] = useState<number[]>([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [last30days, setLast30days] = useState(false);
  const [dueDate, setDueDate] = useState('');
  const [filterByNewActivity, setFilterByNewActivity] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);



  //total
  const [newTasks, setNewTasks] = useState(0);
  const [openTasks, setOpenTasks] = useState(0);
  const [overdueTasks, setOverdueTasks] = useState(0);
  const [doneTasks, setDoneTasks] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);

  const [isFiltering, setIsFiltering] = useState(false);

  const [filterPayload, setFilterPayload] = useState({
    startDate: null,
    endDate: null,
    clientId: [],
    typeId: [],
    module: [],
    searchType: 0
  });
  const [selectedAssignee, setSelectedAssignee] = useState<any>([]);
  const [selectedSearchType, setSelectedSearchType] = useState(filterPayload.searchType);

  const [filterSearch, setFilterSearch] = useState('');
  const [tableSearchLoading, setTableSearchLoading] = useState(false);

  const [openCustomReminder, setOpenCustomReminder] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const [loadMoreRows, setLoadMoreRows] = useState(false);
  const [oldRows, setOldRows] = useState<any>([]);


  const [scrollingToEmptyRow, setScrollingToEmptyRow] = useState(false);


  const [take, setTake] = useState(10);
  const [noMoreRows, setNoMoreRows] = useState(false);
  const [notLoad, setNotLoad] = useState(false);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchInput(value);

      const debounceTimeout = setTimeout(() => {
        setOldRows([]);
        setTake(10);
        setNoMoreRows(false);
        setFilterPayload({ ...filterPayload, searchType: 1 });
        setSearch(value);
      }, 500);

      return () => clearTimeout(debounceTimeout);
    },
    [setOldRows, setTake, setFilterPayload, filterPayload, setSearch]
  );

  // useEffect(() => {
  //   if (loadMoreRows) getTaskListCall()
  // }, [loadMoreRows])

  useEffect(() => {
    getUserProfileCall();
    // if (firstRender) getTaskListCall();
    // if (firstRender) getTaskListInputCall();

  }, []);

  // useEffect(() => {
  //   if (!firstRender) getTaskListCall();
  // }, [modify, search, selectedStatus, last30days, paginationModel, filterPayload, dueDate, selectedClient, selectedAssignee, selectedType, selectedTaskName, selectedPeriod, selectedModule, selectedPriority, selectedRecurring, filterByNewActivity]);

  // useEffect(() => {
  //   getTaskListCall();
  // }, [])

  useEffect(() => {

    console.log("selected statuses", selectedStatus)
    const filteredTasksList = originalTasksTable?.filter((val: any) => {

      const searchCheck = search ?
        (val.title?.toLowerCase().includes(search.toLowerCase())
          || val.description?.toLowerCase().includes(search.toLowerCase())
          || val.note?.toLowerCase().includes(search.toLowerCase())
          || val.dueDate?.toLowerCase().includes(search.toLowerCase())
          || val.startDate?.toLowerCase().includes(search.toLowerCase())
          || val.clientName?.toLowerCase().includes(search.toLowerCase())
          || val.assigneeName?.toLowerCase().includes(search.toLowerCase())
        ) : true;

      const nameCheck = selectedTaskName.length > 0 ? selectedTaskName.includes(val.title) : true;
      const statusCheck = selectedStatus.length > 0 ? selectedStatus.includes(val.status) : true;
      const typeCheck = selectedType.length > 0 ? selectedType.includes(val.type) : true;
      const clientNameCheck = selectedClient.length > 0 ? selectedClient.includes(val.clientName) : true;
      // const assigneeNameCheck = selectedAssignee.length > 0 ? selectedAssignee.includes(val.assigneeName) : true;
      const isRecurringCheck = selectedRecurring?.trim() !== "" ?
        selectedRecurring === 'Yes' ? val.recurringPeriod !== 0 :
          val.recurringPeriod === 0
        : true;
      const recurringPeriodCheck = selectedPeriod.length > 0 ? selectedPeriod.includes(val.recurringPeriod) : true;
      const priorityCheck = selectedPriority.length > 0 ? selectedPriority.includes(val.priority) : true;
      const moduleCheck = selectedModule.length > 0 ? selectedModule.includes(val.module) : true;
      const assigneeCheck = selectedAssignee.length > 0 ? selectedAssignee.includes(val.assigneeId) : true;

      const newActivityCheck = filterByNewActivity ? val.notifications.length > 0 : true


      // const lastNameCheck = selectedLastNames.length > 0 ? selectedLastNames.includes(tm.lastName) : true;
      // const emailCheck = selectedEmails.length > 0 ? selectedEmails.includes(tm.email) : true;
      // const rolesCheck = selectedRoles.length > 0 ? tm.roles.some((role: any) => selectedRoles.includes(role.id)) : true;
      // const lastActiveCheck = selectedLastActiveDates.length > 0 ? selectedLastActiveDates.includes(tm.lastActiveDate) : true;
      // const phoneNbCheck = selectedPhoneNumbers.length > 0 ? selectedPhoneNumbers.includes(tm.phoneNumber) : true;
      // const creationDateCheck = selectedCreationDates.length > 0 ? selectedCreationDates.includes(tm.creationDate) : true;

      return searchCheck && nameCheck && statusCheck && typeCheck && clientNameCheck && isRecurringCheck
        && recurringPeriodCheck && priorityCheck && moduleCheck && assigneeCheck && newActivityCheck

    }
    )

    let openCount = 0;
    let overdueCount = 0;
    let newCount = 0;
    let doneCount = 0;
    let newActivityCount = 0;
    filteredTasksList?.map((t: any) => {
      if (openStatusesIds?.includes(t.status)) openCount += 1
      if (t.status === statusesConsts.NEW_STATUS_ID) newCount += 1
      if (t.status === statusesConsts.OVERDUE_STATUS_ID) overdueCount += 1
      if (t.status === statusesConsts.CLOSED_STATUS_ID) doneCount += 1
      if (t.notifications?.length > 0) newActivityCount += 1


    })
    console.log("originalTaskstable",originalTasksTable)
    console.log("filtered tasks",filteredTasksList)
    setOpenTasks(openCount);
    setOverdueTasks(overdueCount);
    setNewTasks(newCount);
    setDoneTasks(doneCount);
    setNotificationCount(newActivityCount)
    setTasksTable(filteredTasksList)



  }, [search, selectedTaskName, selectedStatus, selectedType, selectedClient, selectedRecurring, selectedPeriod, selectedPriority, selectedModule, selectedAssignee, filterByNewActivity])


  const getUserProfileCall = () => {
    dispatch(setDialogLoading(true));
    getUserProfile(token).then((x) => {
      localStorage.setItem('username', x.username);
      setUserData(x);
      if (!loadMoreRows) {
        // setSelectedAssignee([x.id])
        // setClickedAssignee([x.id])
        setOldRows([]);
        setTake(10);
        setNoMoreRows(false);
        // getTaskListInputCall();
      }
      dispatch(setDialogLoading(false));
      getTaskListInputCall(x.id);
      // if (firstRender) {
      //   setSelectedAssignee([x.id])
      //   setClickedAssignee([x.id])
      //   if(x.id) getTaskListInputCall(); 
      // }

    })
  }
  const listData =
  {
    searchTerm: search ? search : null,
    //// pageNumber: paginationModel.page + 1,
    //// pageSize: paginationModel.pageSize,
    // skip: oldRows?.length,
    // take: take,
    // statusId: selectedStatus?.length > 0 ? selectedStatus : null,
    // last30days: last30days,
    startDate: filterPayload?.startDate || null,
    endDate: filterPayload?.endDate || null,
    //// clientId: filterPayload?.clientId || selectedClient || null,
    // assigneeId: selectedAssignee || null,
    // clientId: selectedClient || null,
    //// typeId: filterPayload?.typeId,
    // typeId: selectedType || null,
    // taskName: selectedTaskName || null,
    // dueDate: dueDate ? dueDate : null,
    // recurringPeriod: selectedPeriod || null,
    // module: selectedModule || null,
    searchType: filterPayload.searchType,
    // notification: filterByNewActivity,
    // priority: selectedPriority || null,
    // isRecurring: isRecurring,
  }

  // useEffect(() => {
  //   if (localStorage.getItem('userId')) {
  //     setSelectedAssignee([localStorage.getItem('userId')])
  //     setClickedAssignee([localStorage.getItem('userId')])
  //   }
  // }, [])

  const gridRef = useRef<any>(null);

  const scrollToBottom = () => {
    if (gridRef.current) {

      const virtualScroller = gridRef.current.querySelector('.MuiDataGrid-virtualScroller');
      if (virtualScroller) {
        virtualScroller.scrollTop = virtualScroller.scrollHeight;
      }
    }
  };

  const scrollToTop = () => {
    if (gridRef.current) {
      const virtualScroller = gridRef.current.querySelector('.MuiDataGrid-virtualScroller');
      if (virtualScroller) {
        virtualScroller.scrollTop = 0;
      }
    }
  };

  const checkNeedToAddEmptyRow = () => {
    const gridContainer = gridRef.current;
    console.log("tesst", gridContainer)
    if (gridContainer) {
      // Get the virtual scroller container from the DataGrid
      const virtualScroller = gridContainer.querySelector(
        ".MuiDataGrid-virtualScroller"
      );
      const rowHeight = 52; // This is the row height in px, you may need to adjust based on your grid configuration.
      const totalRowsHeight = rowHeight * (tasksTable.length);

      if (totalRowsHeight >= virtualScroller.clientHeight) {
        console.log("'yes yes")
        return true
      } else {
        console.log("no no")
        return false
      }
    }
  }

  const getTaskListCall = (allTypes: any, allRecurring: any, allModules: any, loggedInUserId: string) => {
    setAllTypesList(allTypes);
    setAllRecurringList(allRecurring);
    setAllModulesList(allModules)
    const defaultListData = { ...listData, assigneeId: [localStorage.getItem('userId')] }
    if (firstRender) {
      console.log("list Data", listData);
      console.log("seected", selectedAssignee);
      console.log("userId", localStorage.getItem('userId'))
      setFirstRender(false);
    }
    else if (search) setTableSearchLoading(true)
    else if (!loadMoreRows) {
      dispatch(setDialogLoading(true))
      setInitialListLoading(true);
    }
    getTaskList(firstRender ? defaultListData : listData).then((x) => {
      if (loadMoreRows) {
        if (x.tasks.length === 0 && checkNeedToAddEmptyRow()) {
          console.log("hree to add a row ")
          setNoMoreRows(true);
          const emptyExists = tasksTable?.find((r: any) => r.id === 'Empty') ? true : false
          if (!emptyExists) {
            console.log("rows", tasksTable)
            let newUserbj = {
              id: "Empty",
              email: null,
              firstName: null,
              lastName: null,
              phoneNumber: null,
              position: null,
              creationDate: null,
              statusId: 0,
              roles: null,
              lastActiveDate: null,
              taskCount: 0

            }
            let tmp = [...tasksTable];
            tmp.splice(tasksTable?.length, 0, newUserbj);
            setTasksTable(tmp);
            setNotLoad(true);
            setTimeout(() => {
              scrollToBottom()
            }, 200)

            setTimeout(() => {
              setNotLoad(false);
            }, 4000)

          }

        }
        else {
          let old = tasksTable
          setOldRows(old)
          let tmp: any[] = [];
          tmp.push(...old, ...(x.tasks));
          // tmp.filter((t)=> t.id !== 'Empty')
          setOldRows(tmp);
          setTasksTable(tmp)
        }
      }
      else {

        scrollToTop()
        const uniqueTitles = x.tasks?.reduce((uniqueTasks: any[], task: { title: string }) => {
          if (!uniqueTasks.find(existingTask => existingTask === task.title)) {
            uniqueTasks.push(task.title);
          }
          return uniqueTasks;
        }, [])
        setTaskName(uniqueTitles);

        const uniqueStatuses = [];
        if (x.tasks?.find((t: any) => t.status === statusesConsts.NEW_STATUS_ID)) uniqueStatuses.push({ id: statusesConsts.NEW_STATUS_ID, name: statusesConsts.NEW_STATUS_NAME })
        if (x.tasks?.find((t: any) => t.status === statusesConsts.OPEN_STATUS_ID)) uniqueStatuses.push({ id: statusesConsts.OPEN_STATUS_ID, name: statusesConsts.OPEN_STATUS_NAME })
        if (x.tasks?.find((t: any) => t.status === statusesConsts.OVERDUE_STATUS_ID)) uniqueStatuses.push({ id: statusesConsts.OVERDUE_STATUS_ID, name: statusesConsts.OVERDUE_STATUS_NAME })
        if (x.tasks?.find((t: any) => t.status === statusesConsts.IN_PROGRESS_STATUS_ID)) uniqueStatuses.push({ id: statusesConsts.IN_PROGRESS_STATUS_ID, name: statusesConsts.IN_PROGRESS_STATUS_NAME })
        if (x.tasks?.find((t: any) => t.status === statusesConsts.CLOSED_STATUS_ID)) uniqueStatuses.push({ id: statusesConsts.CLOSED_STATUS_ID, name: statusesConsts.CLOSED_STATUS_NAME })
        if (x.tasks?.find((t: any) => t.status === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID)) uniqueStatuses.push({ id: statusesConsts.WAITING_FOR_OTHERS_STATUS_ID, name: statusesConsts.WAITING_FOR_OTHERS_STATUS_NAME })
        if (x.tasks?.find((t: any) => t.status === statusesConsts.UPCOMING_STATUS_ID)) uniqueStatuses.push({ id: statusesConsts.UPCOMING_STATUS_ID, name: statusesConsts.UPCOMING_STATUS_NAME })
        setStatusesList(uniqueStatuses);

        const uniqueTypes: any[] = [];
        allTypes?.map((t: any) => {
          if (x.tasks?.find((val: any) => val.type === t.id)) {
            uniqueTypes.push(t)
          }
        })
        setTypesList(uniqueTypes);

        const uniqueClients = x.tasks?.reduce((uniqueTasks: any[], task: { clientName: string }) => {
          if (!uniqueTasks.find(existingTask => existingTask === task.clientName)) {
            uniqueTasks.push(task.clientName);
          }
          return uniqueTasks;
        }, [])
        setClientsList(uniqueClients);

        const recYesNo = [];
        if (x.tasks?.find((t: any) => t.recurringPeriod === 0)) recYesNo.push('No')
        if (x.tasks?.find((t: any) => t.recurringPeriod !== 0)) recYesNo.push('Yes')
        setRecurringTaskList(recYesNo);

        const uniqueRecurringPeriods: any[] = [];
        allRecurring?.map((t: any) => {
          if (x.tasks?.find((val: any) => val.recurringPeriod === t.id)) {
            uniqueRecurringPeriods.push(t)
          }

        })
        setRecurringPeriodsList(uniqueRecurringPeriods);

        const uniquePriorities: any[] = [];
        allPrioritiesList?.map((t: any) => {
          if (x.tasks?.find((val: any) => val.priority === t)) {
            uniquePriorities.push(t)
          }

        })
        setPriorityList(uniquePriorities);


        const uniqueModules: any[] = [];
        allModules?.map((t: any) => {
          if (x.tasks?.find((val: any) => val.module === t.id)) {
            uniqueModules.push(t)
          }

        })
        setModuleList(uniqueModules);

        const uniqueInternalAssignees: any[] = [];
        const uniqueExternalAssignees: any[] = [];
        x.tasks?.reduce((uniqueTasks: any[], task: { assigneeId: string, assigneeName: string, assigneeType: number }) => {
          if (task.assigneeId !== null) {
            if (task.assigneeType === 1 && !uniqueInternalAssignees.find(existing => existing.id === task.assigneeId)) {
              uniqueInternalAssignees.push({
                id: task.assigneeId,
                name: task.assigneeName
              });
            }
            else if (task.assigneeType === 2 && !uniqueExternalAssignees.find(existing => existing.id === task.assigneeId)) {
              uniqueExternalAssignees.push({
                id: task.assigneeId,
                name: task.assigneeName
              });
            }
          }

        }, [])
        setInternalAssigneesList(uniqueInternalAssignees);
        setExternalAssigneesList(uniqueExternalAssignees);

        setSelectedStatus(openStatusesIds);
        setClickedStatus(openStatusesIds);

        setIsOpenCardSelected(true);


        setTasksTable(x.tasks);
        setOriginalTasksTable(x.tasks);
        setOldRows(x.tasks)
      }
      // const uniqueTitles = x.allTasks?.reduce((uniqueTasks: { title: string }[], task: { title: string }) => {
      //   if (!uniqueTasks.some(existingTask => existingTask.title === task.title)) {
      //     uniqueTasks.push(task);
      //   }
      //   return uniqueTasks;
      // }, [])
      // setTaskName(uniqueTitles);
      setDataLength(x.total || 0);
      // if (!loadMoreRows) {
      //   setNewTasks(x.totalTasks["New"] ? x.totalTasks["New"] : 0);
      //   const totalOpen =
      //     (x.totalTasks["Open"] ? x.totalTasks["Open"] : 0)
      //     + (x.totalTasks["New"] ? x.totalTasks["New"] : 0)
      //     + (x.totalTasks["Overdue"] ? x.totalTasks["Overdue"] : 0)
      //     + (x.totalTasks["Upcomming"] ? x.totalTasks["Upcomming"] : 0)
      //     + (x.totalTasks["InProgress"] ? x.totalTasks["InProgress"] : 0)
      //     + (x.totalTasks["WaitingForOthers"] ? x.totalTasks["WaitingForOthers"] : 0)
      //   setOpenTasks(totalOpen);
      //   setOverdueTasks(x.totalTasks["Overdue"] ? x.totalTasks["Overdue"] : 0);
      //   setDoneTasks(x.totalTasks["Closed"] ? x.totalTasks["Closed"] : 0);
      // }
      setNotificationCount(x.notificationCount)
      dispatch(setDialogLoading(false));
      setInitialListLoading(false);
      setTableSearchLoading(false);
      // if (firstRender) {
      //   setIsOpenCardSelected(true)
      //   setSelectedStatus(openStatusesIds)

      // }
      setLoadMoreRows(false);
      setScrollingToEmptyRow(false);
      setInputDataLoading(false);
      setResetFilter(false);
    });
  }

  const addTaskCall = (taskObject: any, buttonNb: any) => {
    console.log('taskObject', taskObject)
    let today = moment(new Date())
    let dueMinus2 = moment(new Date(taskObject.dueDate)).subtract(2, 'days');

    // console.log("isRecurring",isRecurring === null);
    // console.log("(selectedType.length !== 0 && selectedType === taskObject.type )",((selectedType.length === 0 || selectedType.length !== 0 && selectedType === taskObject.type) ));
    // console.log("(selectedClient.length !== 0 && selectedClient.includes(taskObject.clientId) )",((selectedClient.length === 0 || selectedClient.length !== 0 && selectedClient.includes(taskObject.clientId)) ));
    // console.log("(selectedAssignee.length !== 0 && selectedAssignee.includes(taskObject.assigneeId))",((selectedAssignee.length === 0 || selectedAssignee.length !== 0 && selectedAssignee.includes(taskObject.assigneeId))));
    // console.log(" (dueDate !== '' && dueDate === taskObject.dueDate)", ((dueDate === '' || dueDate !== '' && dueDate === taskObject.dueDate)))
    // console.log("clickedPeriod.length === 0",selectedPeriod.length === 0)
    // console.log("(selectedPriority.length !== 0 && selectedPriority.includes(taskObject.priority))",((selectedPriority.length !== 0  || selectedPriority.length !== 0 && selectedPriority.includes(taskObject.priority))))
    // console.log("(selectedModule.length !==0 && selectedModule.includes(taskObject.module))",((selectedModule.length === 0 || selectedModule.length !== 0 && selectedModule.includes(taskObject.module))))


    dispatch(setLoading(true));
    addTask(taskObject).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);

      }
      else {
        setOpenTasks(openTasks + 1);
        if (today < dueMinus2) setNewTasks(newTasks + 1)
        dispatch(setCallStatus("Pass"));
        if (buttonNb === 1) {
          let index;
          if (originalTaskId) {
            index = tasksTable.findIndex((task: { id: any; }) => task.id === originalTaskId);

          }
          else {
            if (tasksTable.length === 0) index = 0;
            else index = tasksTable.length - 1;

          }
          if (index !== -1) {
            let newTaskObj = {
              id: x,
              title: taskObject.title,
              description: taskObject.description,
              note: taskObject.note,
              recurringPeriod: taskObject.recurringPeriod,
              priority: taskObject.priority,
              dueDate: taskObject.dueDate,
              module: taskObject.module,
              modulesName: moduleList?.find((m: any) => m.id === taskObject.module)?.name,
              status: today >= dueMinus2 ? statusesConsts.UPCOMING_STATUS_ID : statusesConsts.NEW_STATUS_ID,
              type: taskObject.type,
              startDate: taskObject.startDate,
              clientId: taskObject.clientId,
              clientName: clientsList?.find((cl: any) => cl.id === taskObject.clientId).name,
              assigneeId: taskObject.assigneeId,
              isCreator: true,
              comments: null,
              history: null,
              toBeTagedUsers: null,
              documents: null,
              reminders: [],
              notifications: []

            }
            if (
              (
                ((selectedStatus.includes(statusesConsts.NEW_STATUS_ID) && today < dueMinus2) || (selectedStatus.includes(statusesConsts.UPCOMING_STATUS_ID) && today >= dueMinus2))
                && ((selectedType.length === 0 || selectedType.length !== 0 && selectedType === taskObject.type))
                && ((selectedClient.length === 0 || selectedClient.length !== 0 && selectedClient.includes(taskObject.clientId)))
                && ((selectedAssignee.length === 0 || selectedAssignee.length !== 0 && selectedAssignee.includes(taskObject.assigneeId)))
                && ((dueDate === '' || dueDate !== '' && dueDate === taskObject.dueDate))
                && (isRecurring === null || isRecurring === true)
                && selectedPeriod.length === 0
                && ((selectedPriority.length !== 0 || selectedPriority.length !== 0 && selectedPriority.includes(taskObject.priority)))
                && ((selectedModule.length === 0 || selectedModule.length !== 0 && selectedModule.includes(taskObject.module)))
              )
              || selectedStatus.length === 0
            ) {

              let tmp = [...tasksTable];
              tmp.splice(index + 1, 0, newTaskObj);
              setTasksTable(tmp);

            }
            let tmp = [...tasksTable];
            tmp.splice(index + 1, 0, newTaskObj);
            setOriginalTasksTable(tmp);

          }

          //dispatch(setModify(!modify));
        }

        setTimeout(() => {
          if (buttonNb === 2) navigate(`/task-details?id=${x}`)
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          setOpenAddTaskDialog(false);


        }, 1000);
      }
      dispatch(setLoading(false));
    })

  }

  const getTaskInputDataCall = () => {
    setInputDataLoading(true);
    getTaskInputData(taskObject.module, taskObject.clientId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);

      }
      else {
        setAssigneesList(x.users);
        setInternalAssigneesList(x.internalUsers?.filter((i: any) => i.name.trim() !== "" && i.name !== null));
        if (userType === 2) {
          setExternalAssigneesList(x.externalUsers?.filter((i: any) => i.name.trim() !== "" && i.name !== null));
          setTaskObject({ ...taskObject, clientId: x.clients[0].id })
        }
        setClientsList(x.clients);
        setStatusesList(x.statuses);
        setTypesList(x.types);
        setSearchTypesList(x.searchTypes);
        setRemindersList(x.reminderTypes);
        setModuleList(x.modules);
        setRecurringPeriodsList(x.recurringPeriods);
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 1000);
      }
      setInputDataLoading(false);
    })
  }

  const getTaskListInputCall = (loggedInUserId: string) => {
    setInputDataLoading(true);
    getTaskListInputData().then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);

      }
      else {
        setAssigneesList(x.users);
        setInternalAssigneesList(x.internalUsers?.filter((i: any) => i.name.trim() !== "" && i.name !== null));
        // let user = x.internalUsers?.find((u:any) => u.name === userData?.username)

        //   if(user){
        //     setSelectedAssignee([x.internalUsers?.find((u:any) => u.name === userData?.username)?.id]);
        //     setClickedAssignee([x.internalUsers?.find((u:any) => u.name === userData?.username)?.id]);
        // }

        if (userType === 2) setExternalAssigneesList(x.externalUsers?.filter((i: any) => i.name.trim() !== "" && i.name !== null));
        if (userType === 1) {
          let tmp = x.clients?.flatMap((client: { users: any; }) => client.users);
          setExternalAssigneesList(tmp?.filter((i: any) => i.name.trim() !== "" && i.name !== null))
        }
        // setClientsList(x.clients);
        // setStatusesList(x.statuses);
        // setTypesList(x.types);
        // setSearchTypesList(x.searchTypes);
        // setRemindersList(x.reminderTypes);
        // setModuleList(x.modules);
        // setRecurringPeriodsList(x.recurringPeriods);
        setAllTypesList(x.types);
        setAllRecurringList(x.recurringPeriods)

        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 1000);
      }
      getTaskListCall(x.types, x.recurringPeriods, x.modules, loggedInUserId);
      // setFirstRender(false);
      // setInputDataLoading(false);
    })
  }

  const updateTaskStatusCall = (taskId: string, statusId: number, oldStatusId: number) => {
    setOpenOrCompleteLoading(true)
    updateTaskStatus(taskId, statusId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''))
        }, 1500);
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''))
          console.log('status Id', statusId)
          if (statusId === statusesConsts.CLOSED_STATUS_ID) {
            console.log("changing to close", statusId)

            setTasksTable((prev: any[]) =>
              prev.map(task => task.id === taskId ? { ...task, status: statusesConsts.CLOSED_STATUS_ID } : task)
            )
            if (!selectedStatus.includes(statusesConsts.CLOSED_STATUS_ID) && selectedStatus.length > 0) {
              console.log("closed not selected")
              setTasksTable(tasksTable.filter((r: any) => r.id !== taskId))
            }

            setDoneTasks(doneTasks + 1);
            setOpenTasks(openTasks - 1);

          }
          else if (statusId === statusesConsts.OPEN_STATUS_ID) {
            console.log("changing to open", statusId)
            setTasksTable((prev: any[]) =>
              prev.map(task => task.id === taskId ? { ...task, status: statusesConsts.OPEN_STATUS_ID } : task)
            )
            if (!selectedStatus.includes(statusesConsts.OPEN_STATUS_ID) && selectedStatus.length > 0) {
              console.log("closed is selected")
              setTasksTable(tasksTable.filter((r: any) => r.id !== taskId))
            }
            setDoneTasks(doneTasks - 1);
            setOpenTasks(openTasks + 1);
          }
          else {
            setTasksTable((prev: any[]) =>
              prev.map(task => task.id === taskId ? { ...task, status: statusId } : task)
            )
            if (!selectedStatus.includes(statusId) && selectedStatus.length > 0) {
              setTasksTable(tasksTable.filter((r: any) => r.id !== taskId))
            }
            if (oldStatusId === statusesConsts.NEW_STATUS_ID) setNewTasks(newTasks - 1)
            else if (oldStatusId === statusesConsts.OVERDUE_STATUS_ID) setOverdueTasks(overdueTasks - 1)
          }
          //dispatch(setModify(!modify));
        }, 1500);

      }
      setOpenOrCompleteLoading(false)
    })
  }

  const postReminderCall = (reminderObj: any, reminderId: any, taskId: any) => {

    if (reminderId) {

      if (reminderObj.typeId === 4) dispatch(setLoading(true))
      else dispatch(setDialogLoading(true))
      dispatch(setActionCallFrom('post reminder'))
      inActiveReminder(reminderId).then((x) => {
        if (x.ErrorMessage) {
          dispatch(setErrorMsg(x.ErrorMessage));
          setTimeout(() => {
            dispatch(setErrorMsg(""));
          }, 4500);

        }
        else {
          if (reminderObj.typeId === 4) dispatch(setLoading(true))
          else dispatch(setDialogLoading(true))
          dispatch(setActionCallFrom('post reminder'))
          postReminder(reminderObj).then((x) => {
            if (x.ErrorMessage) {
              dispatch(setErrorMsg(x.ErrorMessage));
              setTimeout(() => {
                dispatch(setErrorMsg(""));
                dispatch(setActionCallFrom(''))
              }, 4500);

            }
            else {
              // setTasksTable((prev: any[]) =>
              //   prev.map(task => task.id === taskId ? { ...task, reminders: [...task.reminders, x] } : task)
              // )
              setTasksTable((prev: any[]) =>
                prev.map(task => task.id === taskId ? { ...task, reminders: [x] } : task)
              )
              console.log("[...task.reminders,x] if reminder id", tasksTable)
              console.log("x", x)
              console.log('task id', taskId)
              setOpenCustomReminder(false);
              //dispatch(setModify(!modify));
              dispatch(setActionCallFrom(''))
            }
            dispatch(setLoading(false))
            dispatch(setDialogLoading(false))

          })

        }
      })


    }
    else {
      if (reminderObj.typeId === 4) dispatch(setLoading(true))
      else dispatch(setDialogLoading(true))
      dispatch(setActionCallFrom('post reminder'))
      postReminder(reminderObj).then((x) => {
        if (x.ErrorMessage) {
          dispatch(setErrorMsg(x.ErrorMessage));
          setTimeout(() => {
            dispatch(setErrorMsg(""));
            dispatch(setActionCallFrom(''))
          }, 4500);

        }
        else {
          setTasksTable((prev: any[]) =>
            prev.map(task => task.id === taskId ? { ...task, reminders: [...task.reminders, x] } : task)
          )
          console.log("[...task.reminders,x]", tasksTable)
          setOpenCustomReminder(false);
          //dispatch(setModify(!modify));
          dispatch(setActionCallFrom(''))
        }
        if (reminderObj.typeId === 4) dispatch(setLoading(false))
        else dispatch(setDialogLoading(false))

      })
    }


  }

  const inActiveReminderCall = (reminderId: string, taskId: any) => {
    dispatch(setDialogLoading(true))
    inActiveReminder(reminderId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);

      }
      else {
        //dispatch(setModify(!modify));
        setTasksTable((prev: any[]) =>
          prev.map(task => task.id === taskId ? { ...task, reminders: [] } : task)
        )

      }
      dispatch(setDialogLoading(false))
    })

  }



  useEffect(() => {

    let filtered = tasksTable
    if (selectedStatus.length > 0) {
      filtered = filtered?.filter((task: number) => selectedStatus?.includes(task));
    }

    if (last30days) {
      filtered = filtered.filter((task: string | number | Date) => {
        const taskCreationDate = new Date(task);
        return taskCreationDate.getTime() >= Date.now() - 30 * 24 * 60 * 60 * 1000;
      });
    }
    setFilteredTasks(filtered);

  }, [selectedStatus, last30days, tasksTable]);

  const handleCardClick = (status: number[], isOpenCard: boolean, isFilterByNewActivity: boolean) => {
    setOldRows([]);
    setTake(10);
    setNoMoreRows(false);
    if (isOpenCard) {
      setIsOpenCardSelected(!isOpenCardSelected);
      if (isOpenCardSelected) {
        setSelectedStatus([])
        //setFilterByNewActivity(false);
      }
      else {
        console.log("status", status)
        setSelectedStatus(status)
        setClickedStatus(status);
      }
    }
    else if (isFilterByNewActivity) {
      setFilterByNewActivity(!filterByNewActivity);
      //setSelectedStatus([]);
      //setIsOpenCardSelected(false);

    }
    else {
      console.log("testtt", status)
      //setFilterByNewActivity(false);
      setIsOpenCardSelected(false);
      if (selectedStatus.length === openStatusesIds.length && selectedStatus.every((value, index) => value === openStatusesIds[index])) {
        console.log("1", status)

        setSelectedStatus(status)
        setClickedStatus(status)
      }
      else {

        if (isOpenCardSelected) {
          setSelectedStatus(status)
          setClickedStatus(status)
        }
        else {

          const removeAlreadySelectedRoles = selectedStatus.filter(i => !status.includes(i));
          const filterNewSelectedRoles = status.filter(i => !selectedStatus.includes(i));
          const combinedAr = [...removeAlreadySelectedRoles, ...filterNewSelectedRoles];
          setSelectedStatus(combinedAr)
          setClickedStatus(combinedAr)
          console.log("2", combinedAr)
        }

      }
    }

    // setSelectedStatus((prevSelected = []) => {
    //   const isAlreadySelected = status.every(s => prevSelected.includes(s));
    //   if (isAlreadySelected) {
    //     return prevSelected.filter(s => !status.includes(s));
    //   } else {
    //     return [...prevSelected, ...status];
    //   }
    // });
  };
  // useEffect(() => {
  //   if (selectedStatus.length === openStatusesIds.length &&
  //     openStatusesIds.every((status) => selectedStatus.includes(status))) {
  //     setIsOpenCardSelected(true);
  //   }
  //   else {
  //     setIsOpenCardSelected(false);
  //   }
  // }, [selectedStatus])

  //tmpppp
  // useEffect(() => {
  //   setSelectedStatus(openStatusesIds)
  //   setClickedStatus(openStatusesIds);
  //   setIsOpenCardSelected(true)
  // }, [])

  const handleLast30DaysToggle = () => {
    setLast30days((prev) => !prev);
  };

  const handleFilterSubmit = (filterData: any) => {
    setOldRows([]);
    setTake(10);
    setNoMoreRows(false);
    setSearch(filterData.search);
    setFilterPayload({
      ...filterData,
      clientId: filterData.clientId || null,
      typeId: filterData.typeId || null,
      module: filterData.module || null,
      searchType: filterData.searchType || 1
    });
    if (filterPayload.clientId) {
      setSelectedClient(filterData.clientId);
    } else {
      setSelectedClient([]);
    }
    if (filterPayload.typeId) {
      setSelectedType(filterData.typeId);
    } else {
      setSelectedType([]);
    }
    if (filterPayload.module) {
      setSelectedModule(filterData.module)
    } else {
      setSelectedModule([]);
    }
    if (filterPayload.searchType) {
      setSelectedSearchType(filterData.searchType)
    } else {
      setSelectedSearchType(1);
    }
    dispatch(setModify(!modify));
  };

  useEffect(() => {
    if (!resetFilter) {
      if (filterPayload.clientId !== undefined) {
        setSelectedClient(filterPayload.clientId);
      }
      if (filterPayload.typeId !== undefined) {
        setSelectedType(filterPayload.typeId);
      }
      if (filterPayload.module !== undefined) {
        setSelectedModule(filterPayload.module);
      }

    }

  }, [filterPayload]);

  useEffect(() => {
    // if (localStorage.getItem('userType') === '1') {
    if (openAddTaskDialog) getTaskInputDataCall();
    // else 
    // getTaskListInputData()
    // }

  }, [openAddTaskDialog, taskObject.module, taskObject.clientId, taskObject.type])

  // let screenHeight = window.innerHeight; // Get the height of the screen
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>

      <Grid container direction='column' width='100%' height='100%'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={dialogLoading || initiallistLoading || inputDataLoading || openOrCompleteLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>


        <Grid container direction="column" sx={{ width: '100%' }}
          height='268px'
        // style={{
        //   position: 'sticky',
        //   top: 0, // Position at the top when scrolling
        //   backgroundColor: '#F7F9FF', // Ensure the sticky container has a background
        //   zIndex: 10, // Ensure it appears on top of other content
        //   height: openDrawer?'470px':'280px'
        // }}
        >
          <Grid item>
            {/* <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', display: 'flex', gap: '8px' }}>
              {!initiallistLoading && userData?.username && (
                <>
                  <Message id="text.hello" style={{ fontWeight: 'bold', fontSize: '24px', lineHeight: 'initial' }} /> {userData.username}!
                </>
              )}
            </Typography> */}
            <Message id="task.my-tasks" style={{ fontSize: '26px', lineHeight: 'initial' }} />

          </Grid>
          <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} marginBottom={0.3}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                else navigate('/tasks')
              }
              }
            >
              {/* <HomeOutlinedIcon style={{ color: '#93919A' }} /> */}
              <img src={HomeIcon} width='20px' height='20px' style={{ marginTop: '5px' }} />
            </span> <Message id="user.profile.home" className="Field-input desktop-paragraph font-weight-400 pointerText"
              onClick={() => {
                if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                else navigate('/tasks')
              }
              }
            /> / <Message id="task.tasks" className="Field-input desktop-paragraph font-weight-400" />
          </Grid>
          <Grid container direction='row'
            // columnSpacing={2} rowSpacing={1} marginBottom={1} 
            spacing={1}
            sx={{ flexWrap: 'nowrap', overflowX: 'auto', maxWidth: '1150px', display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
            <Grid item
              sx={{ flex: '1 1 160px', minWidth: '160px', maxWidth: '215px', marginRight: '8px' }}
            //xs={12} sm={6} md={3}
            >
              <TaskCard title={intl.formatMessage({ id: 'task.open-tasks' })} count={openTasks} icon={Sun} gradientColors={['#FBE6B1', '#F9F1DA']} backGroundColor={['#FBE6B1', '#F9F1DA']} status={[1, 2, 3, 4, 6, 7]} onCardClick={handleCardClick} isSelected={isOpenCardSelected} isOpenCard={true} isFilterByNewActivity={false} />
            </Grid>
            <Grid item
              sx={{ flex: '1 1 160px', minWidth: '160px', maxWidth: '215px', marginRight: '8px' }}
            //xs={12} sm={6} md={3}
            >
              <TaskCard title={intl.formatMessage({ id: 'task.overdue-tasks' })} count={overdueTasks} icon={Fire} gradientColors={['#F7E6D9', '#F7E6D9']} backGroundColor={['#F17D72', '#F9C69F']} status={[3]} onCardClick={handleCardClick} isSelected={selectedStatus.includes(3) && !isOpenCardSelected} isOpenCard={false} isFilterByNewActivity={false} />
            </Grid>
            <Grid item
              sx={{ flex: '1 1 160px', minWidth: '160px', maxWidth: '215px', marginRight: '8px' }}
            //xs={12} sm={6} md={3}
            >
              <TaskCard title={intl.formatMessage({ id: 'task.new-tasks' })} count={newTasks} icon={Calendar} gradientColors={['#C8EBFF', '#69BAE8']} backGroundColor={['#C8EBFF', '#69BAE8']} status={[1]} onCardClick={handleCardClick} isSelected={selectedStatus.includes(1) && !isOpenCardSelected} isOpenCard={false} isFilterByNewActivity={false} />
            </Grid>
            <Grid item
              sx={{ flex: '1 1 160px', minWidth: '160px', maxWidth: '215px', marginRight: '8px' }}
            //xs={12} sm={6} md={3}
            >
              <TaskCard title={intl.formatMessage({ id: 'task.done-tasks' })} count={doneTasks} icon={Bookmark} gradientColors={['#CCF0EB', '#00B69B']} backGroundColor={['#CCF0EB', '#99C1BB']} status={[5]} onCardClick={handleCardClick} isSelected={selectedStatus.includes(5) && !isOpenCardSelected} isOpenCard={false} isFilterByNewActivity={false} />

            </Grid>
            <Grid item
              sx={{ flex: '1 1 160px', minWidth: '160px', maxWidth: '215px', marginRight: '8px' }}
            >
              <TaskCard title={intl.formatMessage({ id: 'task.new-activity' })} count={notificationCount} icon={Bookmark} gradientColors={['#87ADF6', '#D2C9F1']} backGroundColor={['#87ADF6', '#D2C9F1']} status={[5]} onCardClick={handleCardClick} isSelected={filterByNewActivity} isOpenCard={false} isFilterByNewActivity={true} />

            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item className='desktop-header-5'><Message id="task.my-tasks" className='desktop-header-5 font-weight-400 Field-input' /></Grid> */}

        <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5}
          // height={screenHeight - 368}
          height='100px'
        >

          {/* <Grid item container marginTop={2} alignItems='center'> */}

          {errorMsg ?
            <Grid item className='errorText' marginLeft={2} paddingTop={2} >{errorMsg}</Grid> :
            <></>
          }
          {/* </Grid> */}
          <Grid item container bgcolor='#FEFEFF' borderRadius='8px' boxShadow='0px 2px 4px 0px #0000001F' padding={1}
          >
            <Grid item container direction='row' columnGap={2} padding={1}>
              <Grid item xs={5}>
                <TextField
                  value={searchInput}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                    style: {
                      borderRadius: '35px',
                      fontFamily: 'DM Sans',
                      height: '48px',
                      backgroundColor: '#F7F9FF',
                      // border: '1px solid #E6E6E8'
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#E6E6E8", // Sets the border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#E6E6E8", // Ensures border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#E6E6E8", // Ensures border color when focused
                      },
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontWeight: "bold",
                    },
                  }}
                  placeholder={intl.formatMessage({ id: 'text.search' })}
                  // onChange={(e) => {
                  //   setOldRows([]);
                  //   setTake(10)
                  //   setFilterPayload({ ...filterPayload, searchType: 1 });
                  //   setSearch(e.target.value)
                  // }
                  // }
                  onChange={handleSearchChange}
                />
              </Grid>
              <Grid item style={{ border: '1px solid #E6E6E8', borderRadius: '8px', display: 'flex', alignItems: 'center', padding: 10, cursor: 'pointer' }}
                onClick={() => {
                  setResetFilter(true);
                  setOldRows([]);
                  setTake(10);
                  setNoMoreRows(false);
                  setSearch('');
                  setSearchInput('');
                  setLast30days(false);
                  setSelectedStatus([]);
                  setIsOpenCardSelected(false);
                  setIsFiltering(false);

                  setFilterPayload({ startDate: null, endDate: null, clientId: [], typeId: [], module: [], searchType: 0 });
                  setDueDate('');
                  setSelectedClient([]);
                  setSelectedAssignee([]);
                  setSelectedType([]);
                  setSelectedModule([]);
                  setSelectedPriority([]);
                  setSelectedPeriod([]);
                  setSelectedRecurring('');
                  dispatch(setModify(!modify))
                  setClickedClient([]);
                  setClickedModule([]);
                  setClickedPriority([]);
                  setClickedStatus([]);
                  setClickedType([]);
                  setClickedPeriod([]);
                  setClickedAssignee([]);
                  setClickedRecurring('');
                  setActiveTooltip(null);
                  setSelectedTaskName([]);
                  setIsRecurring(null);
                }
                }><RestartAltIcon htmlColor='#C3C2C7' />
              </Grid>
              {/* <Grid item width='140px'>
                <button className='greyButton'
                  style={{ backgroundColor: last30days ? "#C8EBFF" : "", height: '48px' }}
                  onClick={handleLast30DaysToggle}
                >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center' style={{ color: '#93919A' }}>
                    <CalendarTodayOutlinedIcon fontSize="small" color="disabled" />
                    <Message id="task.last-30-days" className='Field-input font-weight-700 labelslink neutral-3' />
                  </Grid>

                </button>
              </Grid> */}
              <Grid item width='100px'>
                <button className='greyButton'
                  style={{ height: '48px', borderColor: '#E6E6E8', backgroundColor: isFiltering ? "#ebebeb" : "" }}
                  onClick={() => {
                    setFilterDialog(true);
                  }}
                >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center' style={{ color: '#93919A' }}>
                    <FilterAltOutlinedIcon fontSize="small" />
                    <Message id="task.filter" className='Field-input font-weight-700 labelslink' />
                  </Grid>

                </button>
              </Grid>
              {/* <Grid item width='120px'>
                <button className='greyButton'
                //   onClick={() => {
                //    
                //   }
                //   }
                >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center' style={{ color: '#93919A' }}>
                    <ViewWeekOutlinedIcon fontSize="small" />
                    <Message id="task.columns" className='Field-input font-weight-700 labelslink neutral-3' />
                  </Grid>

                </button>
              </Grid> */}
              <Grid item width='172px'>
                <button className='blueButton'
                  style={{ height: '48px' }}
                  onClick={() => {
                    setOpenAddTaskDialog(true)
                  }
                  }
                  disabled={
                    (userType === 1 && !userRoles?.includes(userRolesConst.ADMIN_ID) && !userRoles?.includes(userRolesConst.TAX_ADVISOR_ID) && !userRoles?.includes(userRolesConst.CLERK_ID))
                  }
                >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center'>
                    <GroupAddOutlinedIcon />
                    <Message id="task.create-task" className='Field-input font-weight-700 labelslink offwhite-50' />
                  </Grid>

                </button>
              </Grid>
            </Grid>
            <TasksTable
              rows={tasksTable}
              setRows={setTasksTable}
              taskName={taskName}
              dataLength={dataLength}
              setDataLength={setDataLength}
              modify={modify}
              setModify={setModify}
              open={openAddTaskDialog}
              setOpen={setOpenAddTaskDialog}
              statusesList={statusesList}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              selectedAssignee={selectedAssignee}
              setSelectedAssignee={setSelectedAssignee}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              selectedTaskName={selectedTaskName}
              setSelectedTaskName={setSelectedTaskName}
              selectedModule={selectedModule}
              setSelectedModule={setSelectedModule}
              selectedPriority={selectedPriority}
              setSelectedPriority={setSelectedPriority}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              dueDate={dueDate}
              setDueDate={setDueDate}
              typesList={typesList}
              moduleList={moduleList}
              assigneesList={assigneesList}
              internalAssigneesList={internalAssigneesList}
              externalAssigneesList={externalAssigneesList}
              setExternalAssigneesList={setExternalAssigneesList}
              clientsList={clientsList}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              // pageNb={pageNb}
              // setPageNb={setPageNb}
              // pageSize={pageSize}
              // setPageSize={setPageSize}
              recurringPeriodsList={recurringPeriodsList}
              taskObject={taskObject}
              setTaskObject={setTaskObject}
              updateTaskStatusCall={updateTaskStatusCall}
              remindersList={remindersList}
              postReminderCall={postReminderCall}
              inActiveReminderCall={inActiveReminderCall}
              setOpenCustomReminder={setOpenCustomReminder}
              openCustomReminder={openCustomReminder}
              tableSearchLoading={tableSearchLoading}
              clickedModule={clickedModule}
              setClickedModule={setClickedModule}
              clickedPriority={clickedPriority}
              setClickedPriority={setClickedPriority}
              clickedClient={clickedClient}
              setClickedClient={setClickedClient}
              clickedStatus={clickedStatus}
              setClickedStatus={setClickedStatus}
              clickedType={clickedType}
              setClickedType={setClickedType}
              clickedAssignee={clickedAssignee}
              setClickedAssignee={setClickedAssignee}
              clickedPeriod={clickedPeriod}
              setClickedPeriod={setClickedPeriod}
              clickedTaskName={clickedTaskName}
              setClickedTaskName={setClickedTaskName}
              activeTooltip={activeTooltip}
              setActiveTooltip={setActiveTooltip}
              loadMoreRows={loadMoreRows}
              setLoadMoreRows={setLoadMoreRows}
              setOldRows={setOldRows}
              selectedRecurring={selectedRecurring}
              setSelectedRecurring={setSelectedRecurring}
              clickedRecurring={clickedRecurring}
              setClickedRecurring={setClickedRecurring}
              isRecurring={isRecurring}
              setIsRecurring={setIsRecurring}
              isOpenCardSelected={isOpenCardSelected}
              setIsOpenCardSelected={setIsOpenCardSelected}
              openStatusesIds={openStatusesIds}
              take={take}
              setTake={setTake}
              setOriginalTaskId={setOriginalTaskId}
              doneTasks={doneTasks}
              setDoneTasks={setDoneTasks}
              openTasks={openTasks}
              setOpenTasks={setOpenTasks}
              overdueTasks={overdueTasks}
              setOverdueTasks={setOverdueTasks}
              newTasks={newTasks}
              setNewTasks={setNewTasks}
              scrollingToEmptyRow={scrollingToEmptyRow}
              setScrollingToEmptyRow={setScrollingToEmptyRow}
              dialogLoading={dialogLoading}
              initiallistLoading={initiallistLoading}
              inputDataLoading={inputDataLoading}
              openOrCompleteLoading={openOrCompleteLoading}
              noMoreRows={noMoreRows}
              setNoMoreRows={setNoMoreRows}
              gridRef={gridRef}
              notLoad={notLoad}
              setNotLoad={setNotLoad}
              recurringTaskList={recurringTaskList}
              priorityList={priorityList}
              setOriginalTasksTable={setOriginalTasksTable}
            />
          </Grid>
        </Grid>

        <CreateTask
          loading={loading}
          open={openAddTaskDialog}
          setOpen={setOpenAddTaskDialog}
          callStatus={callStatus}
          errorMsg={errorMsg}
          confirmAction={addTaskCall}
          assigneesList={assigneesList}
          clientsList={clientsList}
          statusesList={statusesList}
          typesList={typesList}
          moduleList={moduleList}
          recurringPeriodsList={recurringPeriodsList}
          internalAssigneesList={internalAssigneesList}
          externalAssigneesList={externalAssigneesList}
          setExternalAssigneesList={setExternalAssigneesList}
          taskObject={taskObject}
          setTaskObject={setTaskObject}
          inputDataLoading={inputDataLoading}
        />

        <FilterTask
          loading={loading}
          open={filterDialog}
          setOpen={setFilterDialog}
          callStatus={callStatus}
          errorMsg={errorMsg}
          confirmAction={handleFilterSubmit}
          clientsList={clientsList}
          selectedClientId={selectedClient}
          selectedTypeId={selectedType}
          selectedModule={selectedModule}
          typesList={typesList}
          searchTypesList={searchTypesList}
          moduleList={moduleList}
          search={filterSearch}
          setSearch={setFilterSearch}
          currentSearch={search}
          selectedSearchTypeId={selectedSearchType}
          clickedModule={clickedModule}
          setClickedModule={setClickedModule}
          clickedClient={clickedClient}
          setClickedClient={setClickedClient}
          clickedType={clickedType}
          setClickedType={setClickedType}
          isFiltering={isFiltering}
          setIsFiltering={setIsFiltering}
        />
      </Grid>

    </>
  );
}

export default ManageTasks;