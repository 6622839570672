import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Grid, MenuItem, TextField, InputAdornment, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Message from '../../../sharedComponents/ui/Message';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useIntl } from 'react-intl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import moment from 'moment';
import { useAppSelector } from '../../../app/hooks';
import { selectLocale } from '../../../app/slices/localsSlice';
import { getCallStatus, getErrorMsg } from '../../../app/slices/apiCallSlice';
import { getLoadingState } from '../../../app/slices/loadingSlice';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export interface DialogProps {
    open: boolean;
    setOpen: CallableFunction;
    confirmAction: CallableFunction,
    taskId: any,
    recurringObject: any;
    originalRecurringObject: any;
    setRecurringObject: any;
}

const CustomRecurringDialog = (props: DialogProps) => {

    const { open, setOpen, confirmAction, taskId, recurringObject, originalRecurringObject, setRecurringObject
    } = props;

    const daysInEnglish = ["M", "T", "W", "T", "F", "S", "S"];
    const daysInGerman = ["M", "D", "M", "D", "F", "S", "S"];

    const locale = useAppSelector(selectLocale);
    const errorMsg = useAppSelector(getErrorMsg);
    const loading = useAppSelector(getLoadingState);
    const callStatus = useAppSelector(getCallStatus);

    const days = locale === 'en' ? daysInEnglish || [] : daysInGerman || [];
    const [selectedDays, setSelectedDays] = useState<any>([]);
    const intl = useIntl();

    const [neverHover, setNeverHover] = useState(false);
    const [neverSelected, setNeverSelected] = useState(false);

    const [onHover, setOnHover] = useState(false);
    const [onSelected, setOnSelected] = useState(false);
    const [fromDate, setFromDate] = useState(null);

    const [afterHover, setAfterHover] = useState(false);
    const [afterSelected, setAfterSelected] = useState(false);
    const [afterNb, setAfterNb] = useState<any>();
    const [dateError,setDateError]=useState(false);
    const [noChange, setNoChange] = useState(false);

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => {
        // Updating state with an empty object to trigger a re-render
        updateState({});
    }, []);

    // const recurringObject = {
    //     taskId: taskId,
    //     days: selectedDays,
    //     isEnding: neverSelected ? false : true,
    //     endDate: fromDate,
    //     endRecurranceNumber: afterNb
    // }

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        // if (originalRecurringObject) setRecurringObject(originalRecurringObject)
        setRecurringObject(originalRecurringObject)
        setOpen(false);
        setNoChange(false);
        setNeverSelected(false);
        setAfterSelected(false);
        setOnSelected(false);
    };

    useEffect(() => {
        if (open) {
            handleOpen();
        }
        else {
            setOpen(false);

        }
    }, [open]);

    const handleDateChange = (newValue: any) => {
        // setFromDate(newValue ? newValue.format('YYYY-MM-DD') : null)
        if(newValue && moment(newValue).isValid() && moment(newValue).isSameOrAfter(moment(), 'day')){
            setRecurringObject((prevState: any) => ({
                ...prevState,
                endDate: newValue ? newValue.format('YYYY-MM-DD') : null
            }));
            setDateError(false);
        }
        else{
            setDateError(true);
        }
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ sx: { borderRadius: "8px", maxWidth: "none", } }}
        >

            <Grid container direction='column' padding='10px 16px 10px 16px' rowGap='10px'>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item> <Message id="task.set-recurring-task" className="Field-input labels font-weight-700" /></Grid>
                    <Grid item style={{ cursor: 'pointer' }} onClick={() => handleClose()}>
                        <CloseIcon />
                    </Grid>
                    <Grid item className='greyLine' width='100%'></Grid>
                </Grid>
                <Grid container rowGap={1} direction='column'>
                    <Grid item> <Message id="task.day-based-recurrence" className="font-16 font-weight-400" /></Grid>
                    <Grid container direction='row' columnGap={2}>
                        {days?.map((d, index) => (
                            <Grid item key={index} className={recurringObject.days?.includes(index + 1) ? 'daySelected' : 'greyCircle'}
                                onMouseOver={(e) => {
                                    const target = e.target as HTMLElement;
                                    target.style.cursor = 'pointer';

                                }}
                                onClick={() => {
                                    if (recurringObject.days.includes(index + 1)) {
                                        console.log("filter", recurringObject.days)
                                        // setSelectedDays(recurringObject.days.filter((day: any) => day !== (index + 1)))
                                        setRecurringObject((prevState: any) => ({
                                            ...prevState,
                                            days: recurringObject.days.filter((day: any) => day !== (index + 1))
                                        }));
                                    }
                                    else {
                                        console.log("add", index);
                                        setRecurringObject((prevState: any) => ({
                                            ...prevState,
                                            days: [...recurringObject.days, (index + 1)]
                                        }));

                                        // setSelectedDays([...recurringObject.days, (index + 1)])
                                    }
                                }}
                            > {d}</Grid>
                        ))}

                    </Grid>

                </Grid>

                <Grid container rowGap='10px' direction='column' marginTop={2}>
                    <Grid item> <Message id="text.ends" className="font-16 font-weight-400" /></Grid>
                    <Grid container direction='row' alignItems='center' columnGap='10px'>
                        <Grid item className={neverHover ? 'darkGreyCircle' : recurringObject.isEnding || neverSelected ? 'blackCircle' : 'lightGreyCircle'}
                            onMouseOver={(e) => {
                                const target = e.target as HTMLElement;
                                target.style.cursor = 'pointer';
                                if (!recurringObject.isEnding) setNeverHover(true)

                            }}
                            onMouseOut={() => {
                                setNeverHover(false)
                            }}
                            onClick={() => {
                                recurringObject.isEnding = !recurringObject.isEnding
                                setNeverSelected(!neverSelected)
                                setAfterSelected(false)
                                setOnSelected(false);
                                // setFromDate(null);
                                // setAfterNb(null)
                                recurringObject.endDate = null
                                recurringObject.endRecurranceNumber = null;
                                forceUpdate();
                                setRecurringObject((prevState: any) => ({
                                    ...prevState,
                                    isEnding: !recurringObject.isEnding,
                                    endDate: null,
                                    endRecurranceNumber: null
                                }));
                            }
                            }>
                            {neverHover || recurringObject.isEnding || neverSelected ?
                                <Grid item className='blueCircle'> </Grid>
                                :
                                <></>
                            }

                        </Grid>
                        <Grid item>
                            <Message id="text.never" className="font-14 font-weight-400" />
                        </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='center'>
                        <Grid container direction='row' alignItems='center' columnGap='10px' width='25%'>
                            <Grid item className={onHover ? 'darkGreyCircle' : onSelected || recurringObject.endDate ? 'blackCircle' : 'lightGreyCircle'}
                                onMouseOver={(e) => {
                                    const target = e.target as HTMLElement;
                                    target.style.cursor = 'pointer';
                                    if (!onSelected) setOnHover(true)

                                }}
                                onMouseOut={() => {
                                    setOnHover(false)
                                }}
                                onClick={() => {
                                    setOnSelected(!onSelected)
                                    setNeverSelected(false);
                                    setAfterSelected(false)
                                    // setAfterNb(null)
                                    // recurringObject.endRecurranceNumber = null;
                                    // forceUpdate();
                                    setRecurringObject((prevState: any) => ({
                                        ...prevState,
                                        isEnding: false,
                                        endRecurranceNumber: null
                                    }));
                                }
                                }>
                                {onHover || onSelected || recurringObject.endDate ?
                                    <Grid item className='blueCircle'> </Grid>
                                    :
                                    <></>
                                }

                            </Grid><Message id="text.on" className="font-14 font-weight-400" /></Grid>
                        <Grid item width='75%'>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
                                <Stack spacing={3}>
                                    <DatePicker
                                        disabled={!onSelected}
                                        label={intl.formatMessage({ id: 'task.from' })}
                                        value={recurringObject.endDate ? moment(recurringObject.endDate, 'YYYY-MM-DD') : null}
                                        onChange={handleDateChange}
                                        minDate={moment()} 
                                        slotProps={{
                                            textField: {
                                                error: dateError,
                                                sx: {
                                                    '& label.Mui-focused': {
                                                        color: '#C3C2C7',
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#B2BAC2',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: '#C3C2C7',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#B2BAC2',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#6F7E8C',
                                                        },
                                                    },
                                                },
                                                InputProps: {
                                                    className: "fieldDesign",
                                                    style: {
                                                        borderRadius: '12px',
                                                        fontFamily: 'DM Sans',
                                                        height: '48px',
                                                        borderTopLeftRadius: '10px',
                                                        backgroundColor: '#F7F9FF',
                                                    },
                                                },
                                            },
                                        }}
                                        format="DD.MM.YYYY"
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Grid>

                    </Grid>

                    <Grid container direction='row' alignItems='center'>
                        <Grid container direction='row' alignItems='center' columnGap='10px' width='25%'>
                            <Grid item className={afterHover ? 'darkGreyCircle' : afterSelected || recurringObject.endRecurranceNumber ? 'blackCircle' : 'lightGreyCircle'}
                                onMouseOver={(e) => {
                                    const target = e.target as HTMLElement;
                                    target.style.cursor = 'pointer';
                                    if (!afterSelected) setAfterHover(true)

                                }}
                                onMouseOut={() => {
                                    setAfterHover(false)
                                }}
                                onClick={() => {
                                    setAfterSelected(!afterSelected)
                                    setOnSelected(false);
                                    setNeverSelected(false);
                                    // recurringObject.isEnding = true
                                    // recurringObject.endDate = null
                                    // setFromDate(null)
                                    setRecurringObject((prevState: any) => ({
                                        ...prevState,
                                        isEnding: false,
                                        endDate: null
                                    }));
                                }}>
                                {afterHover || afterSelected || recurringObject.endRecurranceNumber ?
                                    <Grid item className='blueCircle'> </Grid>
                                    :
                                    <></>
                                }

                            </Grid><Message id="text.after" className="font-14 font-weight-400" /></Grid>
                        <Grid item width='75%'>
                            <TextField
                                disabled={!afterSelected}
                                fullWidth
                                value={recurringObject.endRecurranceNumber || ''}
                                type='number'
                                variant="outlined"
                                sx={{
                                    '& label.Mui-focused': {
                                        color: '#C3C2C7',
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: '#B2BAC2',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#C3C2C7',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#B2BAC2',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#6F7E8C',
                                        },
                                    },
                                }}

                                InputProps={{
                                    style: {
                                        borderRadius: '12px',
                                        fontFamily: 'DM Sans',
                                        height: '50px',
                                        borderTopLeftRadius: '10px',
                                        backgroundColor: '#F7F9FF',
                                    },
                                    startAdornment: null,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Message id="task.recurences" className="font-14 font-weight-400" />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^[0-9]+$/.test(value) || !value) {
                                        // setAfterNb(value);
                                        // recurringObject.endRecurranceNumber = value;
                                        // forceUpdate();

                                        setRecurringObject((prevState: any) => ({
                                            ...prevState,
                                            endRecurranceNumber: value,
                                        }));

                                    }
                                }}
                            />
                        </Grid>

                    </Grid>

                    <Grid item container direction='row' justifyContent='flex-end' marginTop={2} marginBottom={2} columnGap={1.5}>

                        <Grid item xs={3}>
                            <button className='greyButton' onClick={() => handleClose()}> <Message id="button.cancel" className="labels-extra-bold labelsLinks Field-input" /></button>
                        </Grid>
                        <Grid item xs={3}>
                            <button
                                className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
                                disabled={loading || dateError}
                                onClick={() => {
                                    setNoChange(false);
                                    console.log("recurringObject", recurringObject);
                                    console.log("originalRecurringObject", originalRecurringObject);
                                    console.log("noChangeDetected", JSON.stringify(recurringObject) === JSON.stringify(originalRecurringObject))
                                    const noChangeDetected = JSON.stringify(recurringObject) === JSON.stringify(originalRecurringObject)
                                    if (!noChangeDetected) {
                                        confirmAction(recurringObject)
                                    }
                                    else setNoChange(true);
                                }
                                }
                            >
                                {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                    : callStatus === 'Pass' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                        : callStatus === 'Fail' ? "Failed"
                                            : <Message id="button.apply" className="labels-extra-bold labelsLinks Field-input" />}

                            </button>
                        </Grid>
                        {noChange ?
                            <Grid item className='red' xs={12} textAlign='right' marginTop={0.8}>No change detected</Grid>
                            : ""}



                    </Grid>


                </Grid>


            </Grid>



        </Dialog>
    )
}

export default CustomRecurringDialog