import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import Message from '../../../../sharedComponents/ui/Message';
import { addTaxAdvisors } from '../../../../services/admin-services.proxy';
import { useAppSelector } from '../../../../app/hooks';
import { getLoadingState } from '../../../../app/slices/loadingSlice';
import { getCallStatus, getErrorMsg } from '../../../../app/slices/apiCallSlice';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';


export interface componentProps {
    addTaxAdvisorsCall: any
    addedTAs: any;
    setAddedTAs: any;
    setActiveSection: any;
    taxAdvisoryCount: any;

}

const ReviewAndInvite = (props: componentProps) => {
    const { addedTAs, addTaxAdvisorsCall, setActiveSection, taxAdvisoryCount } = props;


    const loading = useAppSelector(getLoadingState);
    const errorMsg = useAppSelector(getErrorMsg);
    const callStatus = useAppSelector(getCallStatus);

    return (
        <Grid item container direction='column' padding={4} rowGap={1} justifyContent='center'>
            <Grid item container direction='column'>
                <Grid item className='black desktop-header-5'> <Message id="ta.review-and-invite" className="Field-input desktop-header-5  font-weight-400 " /> </Grid>
                <Grid item className='black labels bold'><Message id="text.review-and-finalize-invite" className="Field-input labels font-weight-400 " /></Grid>
            </Grid>

            <Grid item>
                <Message id="text.review.user-details" className="black font-weight-700 " />
            </Grid>
            <Grid item className='neutral-2 font-weight-700'> Emails</Grid>


            {addedTAs?.map((ta: any, index: any) => (
                <Grid item className='black'> {ta}</Grid>

            ))}

            <Grid item width='100p%' height='0.5px' bgcolor='#C3C2C7' marginTop={1.5} marginBottom={1.5}></Grid>

            <Grid container direction='column'>
                <Grid item>
                    <Message id="text.review.role-additions" className="black font-weight-700 " />
                </Grid>

                <Grid container direction='row' className="neutral-2 font-weight-700 ">
                    <Grid item xs={3}>
                        <Message id="ta.review.role" className="neutral-2 font-weight-700 " />
                    </Grid>
                    <Grid item xs={4}>
                        New Tax Advisor Admins invited
                    </Grid>
                    <Grid item xs={4}>
                        Existing Tax Advisor Admins
                    </Grid>
                </Grid>

                <Grid container direction='row' className="black font-weight-400 ">
                    <Grid item xs={3}>
                       Tax Advisor Admin
                    </Grid>
                    <Grid item xs={4}>
                        {addedTAs.length}
                    </Grid>
                    <Grid item xs={4}>
                        {taxAdvisoryCount}
                    </Grid>
                </Grid>
            </Grid>


            <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
                {errorMsg ?
                    <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
                    :
                    <></>}

                <Grid item xs={2.5} >
                    <button className='greyButton'
                        onClick={() => setActiveSection('Add')}
                    ><Message id="button.back" className="Field-input labelsLinks labels-extra-bold" /></button>
                </Grid>
                <Grid item xs={2.5}>
                    <button
                        className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
                        disabled={loading || callStatus === 'Pass' || callStatus === 'Fail'}
                        onClick={() => {
                            addTaxAdvisorsCall()
                        }
                        }
                    >
                        {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                            : callStatus === 'Pass' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                : callStatus === 'Fail' ? "Failed"
                                    : <Message id="button.invite" className="Field-input labelsLinks labels-extra-bold" />}
                    </button>
                </Grid>
            </Grid>

        </Grid>

    );
}

export default ReviewAndInvite