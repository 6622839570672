import React, { useEffect, useState } from 'react';
import classes from "../Admin/assets/styles/Navbar.module.css";
import { CircularProgress, Grid } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useNavigate } from 'react-router-dom';
import * as userServices from "../services/user-services.proxy";
import AlertDialog from '../sharedComponents/Dialogs/AlertDialog';
import { selectRoles, selectUserType, setIsCMS, setIsLoggedIn } from '../app/slices/userDataSlice';
import { getDialogLoadingState, setDialogLoading } from '../app/slices/loadingSlice';
import Message from '../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';
import Backdrop from '@mui/material/Backdrop';
import ProfileImage from '../sharedComponents/ui/ProfileImage';
import { googleLogout } from '@react-oauth/google';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authConfig';
import settingIcon from '../assets/images/icons/settingIcon.png';
import * as userRolesConst from "../user-roles-consts";
import ManageNotificationDialog from '../components/Notification/ManageNotificationDialog';

const getUserProfile = userServices.getUserProfile;
const getUserData = userServices.getUserData;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FEFEFF',
        color: '#605E6B',
        borderRadius: '20px',
        boxShadow: '0px 2px 4px 0px #0000001F'
    },
}));

export interface UpperNavProps {
    modifyProfile: boolean,
}

const UpperNavbar = (props: UpperNavProps) => {
    const { modifyProfile } = props
    const intl = useIntl();
    const { instance } = useMsal();


    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    const userType = useAppSelector(selectUserType);
    const userRoles = useAppSelector(selectRoles);

    const token = useAppSelector((state) => state.userData.token);
    const [userData, setUserData] = useState<any>();
    const [userDataObject, setUserDataObject] = useState<any>();
    const [openAlert, setOpenAlert] = useState(false);

    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);

    const dialogLoading = useAppSelector(getDialogLoadingState);

    const [upperUserNameLoading, setUpperUserNameLoading] = useState(false);

    const [openManageNotificationDialog, setOpenManageNotificationDialog] = useState(false);

    useEffect(() => {
        getUserProfileCall();
    }, [modifyProfile]);

    const getUserProfileCall = () => {
        // dispatch(setDialogLoading(true));
        setUpperUserNameLoading(true);
        getUserProfile(token).then((x) => {
            setUserData(x);
            localStorage.setItem('userId', x.id)
            setUpperUserNameLoading(false);
            // dispatch(setDialogLoading(false));
        })
    }

    function deleteAllCookies() {
        const cookies = document.cookie.split(";");

        for (const cookie of cookies) {
            const cookieName = cookie.split("=")[0].trim();
            // Attempt to delete the cookie with common attributes
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname};`;
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${window.location.hostname};`;
        }
    }

    const logoutMethod = () => {
        localStorage.clear();
        dispatch(setIsLoggedIn(false));
        dispatch(setIsCMS(false));
        localStorage.setItem('loginFormState', 'default')
        deleteAllCookies();
        if (localStorage.getItem('ssoType') === 'google')
            googleLogout();
        if (localStorage.getItem('ssoType') === 'microsoft') {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/",
            });
            // instance.loginPopup(loginRequest).catch((e) => {
            //     console.log(e);
            //   });
        }
        navigate('/');
    }

    const updateNotifications = () => {

    }

    return (
        <Grid item container direction='row' xs={12} alignItems='center' justifyContent='flex-end' sx={{ flexWrap: 'nowrap' }} columnSpacing={4}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={upperUserNameLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* {(userType === 2 && (!userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID))) ?
                <></> : */}
                 {/* for later when applying roles check roles sent in manual client login cookie */}

                <Grid item sx={{ flexShrink: 1 }}>
                    <HtmlTooltip
                        open={isSettingsOpen}
                        onClose={() => setIsSettingsOpen(false)}
                        title={
                            <React.Fragment>
                                <Grid item container direction='column' className={classes['dropdownContainer']} rowGap={3}>
                                    <Grid item
                                        onClick={() => {
                                            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                                            else navigate('/users')
                                        }}
                                        className='labelsLinks'> <Message id="text.admin-area" className='Field-input font-weight-400 labelsLinks' />
                                    </Grid>
                                    <Grid item
                                        onClick={() => {
                                            setOpenManageNotificationDialog(true)
                                        }}
                                        className='labelsLinks'> <Message id="text.manage-notification" className='Field-input font-weight-400 labelsLinks' />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    >
                        <Grid item container onClick={() => { setIsSettingsOpen((prev: any) => !prev) }} direction='row' className='black bold pointerText' columnGap={1}>
                            <Message id="text.settings" className='Field-input font-weight-700 labelsLinks' />
                            <img src={settingIcon} />
                        </Grid>
                    </HtmlTooltip>
                </Grid>
            {/* } */}
            <Grid item container
                // xs={2}
                className='labels-extra-bold' alignItems='center' columnGap={1} justifyContent='flex-end'
                // sx={{ flexShrink: 1, flexWrap: 'nowrap', minWidth: '220px' }}
                style={{ width: 'auto', overflow: 'hidden' }}
            >
                {userData?.username && (
                    <ProfileImage username={userData?.username} width='40px' height='40px' fontSize='18px' />
                )}
                <Grid item><span className='labels-extra-bold' style={{ color: '#404040' }}>{userData?.username?.length > 16 ? userData?.username?.slice(0, 13) + "..." : userData?.username}</span><br /> <span className='labels' style={{ color: '#565656', fontWeight: '600' }}>{userData?.position}</span></Grid>
                <Grid item>
                    <HtmlTooltip
                        open={isProfileOpen}
                        onClose={() => setIsProfileOpen(false)}
                        title={
                            <React.Fragment>
                                <Grid item container direction='column' className={classes['dropdownContainer']} rowGap={3}>
                                    <Grid item
                                        onClick={() => {
                                            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/profile')
                                            else navigate('/profile')
                                        }}
                                        className='labelsLinks'> <Message id="user.edit-profile" className='labelsLinks' /></Grid>
                                    {/* <Grid item className='labelsLinks'> Subscription</Grid>
                                    <Grid item className='labelsLinks'> Privacy setting</Grid> */}
                                    <Grid item className='labelsLinks'
                                        onClick={() => { setOpenAlert(true) }}> <Message id="button.logout" className='labelsLinks' /></Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    >
                        <ArrowDropDownCircleOutlinedIcon style={{ cursor: 'pointer', color: '#5C5C5C' }} onClick={() => { setIsProfileOpen((prev: any) => !prev) }} />
                    </HtmlTooltip>
                </Grid>
            </Grid>
            <AlertDialog
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                title={intl.formatMessage({ id: 'logout.confirm-logout' })}
                description={`${intl.formatMessage({ id: 'logout.confirm-description' })}.<br/>${intl.formatMessage({ id: 'logout.please-confirm' })}`}

                confirmAction={logoutMethod}
            />
            <ManageNotificationDialog
                openManageNotificationDialog={openManageNotificationDialog}
                setOpenManageNotificationDialog={setOpenManageNotificationDialog}
                confirmAction={updateNotifications}
            />
        </Grid >
    )
}

export default UpperNavbar